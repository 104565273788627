import React, { useEffect, useReducer, useRef, useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import dayjs from "dayjs";
import IcCross from "@/assets/ic_cross";
import Loader from "./Loader";
import { fetchAllNotifications, readNotification } from "@/services/api";
import useStore from "@/store/useStore";
import IcAcArrowDown from "@/assets/menu/ic_ac_arrow_down";
import { IcPercentage } from "@/assets/menu";
import IcAcArrowUp from "@/assets/menu/ic_ac_arrow_up";
import Button from "./UI/Button";
import { useNavigate } from "react-router-dom";
import { useNetwork } from "@/provider/NetworkContext";
import { useGlobalContext } from "@/hooks/globalContext";

const NotificationCard = ({ onClose }) => {
  const navigate = useNavigate();
  const { userData } = useStore();
  const queryClient = useQueryClient();
  const { showAllNotification, setShowAllNotifications } = useNetwork();
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const [charLimit, setCharLimit] = useState(130);
  const [welcomeNotificationIndex, setWelcomeNotificationIndex] = useState(-1);
  const { toggleRefetch } = useGlobalContext();
  const { data: notifications = [], refetch } = useQuery({
    queryKey: ["getNotifications", userData?._id],
    queryFn: () => fetchAllNotifications(userData?._id),
    enabled: !!userData?._id,
    select: (data) => data.data,
    refetchOnWindowFocus: true,
  });
  let sortedNotifications = [...notifications].sort((a, b) => dayjs(b.createdAt).unix() - dayjs(a.createdAt).unix());

  useEffect(() => {
    // Match the notification message
    const notificationMatched = notifications.some(
      (notification) =>
        notification.messages === "Your whitelisting process is completed. You can now invest in the PNMO presale and get allocation of your tokens."
    );

    setWelcomeNotificationIndex(sortedNotifications.findIndex((f) => f.status === "Welcome"));
    // If the notification is found, toggle the reFetch globally
    if (notificationMatched) {
      toggleRefetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications, toggleRefetch]);

  useEffect(() => {
    setShowAll(false);
    setShowAllNotifications(false);
    // eslint-disable-next-line
  }, [onClose]);

  // const handleDeleteClick = (notificationId) => {
  //   // Optimistically update the notifications on the frontend

  //   const updatedNotifications = sortedNotifications.filter((item) => item._id !== notificationId);
  //   queryClient.setQueryData(["getNotifications", userData?._id], updatedNotifications);

  //   // Call the API to delete the notification
  //   readNotification(notificationId)
  //     .then(() => {
  //       // Invalidate queries to ensure data consistency
  //       queryClient.invalidateQueries(["getNotifications"]);

  //     })
  //     .catch((error) => {
  //       console.error("Error deleting notification:", error);

  //       // Rollback the optimistic update if API call fails
  //       queryClient.setQueryData(["getNotifications", userData?._id], sortedNotifications);

  //     });
  // };
  const handleDeleteClick = (notificationId) => {
    // Optimistically update the notifications on the frontend
    const previousNotifications = queryClient.getQueryData(["getNotifications", userData?._id]);
    queryClient.setQueryData(["getNotifications", userData?._id], (previousNotification) => {
      const updatedNotifications = [previousNotification];
      return updatedNotifications.filter((notification) => notification.id !== notificationId);
    });
    readNotification(notificationId)
      .then(() => {
        queryClient.invalidateQueries(["getNotifications"]);
      })
      .catch((error) => {
        console.error("Error deleting notification:", error);
        queryClient.setQueryData(["getNotifications", userData?._id], previousNotifications);
      });
  };

  const handleViewDetailClick = (item) => {
    if (item.status === "Peaq Rewards Claimed") {
      window.open("https://app.galxe.com/quest/peaq/GCpEitg8Ms", "_blank");
    } else if (item.status === "Customer Onboarded") {
      navigate("/marketplace");
    } else if (item.status === "Welcome") {
      // navigate("/marketplace");
      window.open(
        `https://signup.getsilt.com/?company_app_id=${process.env.REACT_APP_SILT_COMPANY_APP_ID}&customer_user_id=${item.userId}&hide_redirect_button=true`,
        "_blank"
      );
    }
    // else if (item.status === "Referral Reward") {
    //   navigate("/campaign?section=leaderboard");
    // }
    else if (["Token Purchase Request Accepted", "Remaining Amount Request", "Remaining Payment Received", "Refund Request Processed"].includes(item.status)) {
      navigate("/portfolio?tab=requests");
    } else if (item.status === "Refund Processed") {
      navigate("/settings?tab=bank");
    } else if (item.status === "KYC Status Update" || (item.status === "Welcome" && item.messages === "Welcome to penomo, Vijay Ladva!")) {
    } else if (item.status === "Wallet Whitelist Successfull") {
      navigate("/presale");
    } else if (item.status === "Revenue Share Distribution") {
      navigate("/portfolio");
    } else if (item.status === "Token Transfer Completed") {
      navigate("/portfolio");
    }
  };

  const ref = useRef(null);

  const [height, setHeight] = useState(90);

  useEffect(() => {
    function updateSize() {
      if (ref.current) ref.current.dataset.triggerRerender = String(Date.now());
      if (ref.current?.clientHeight) setHeight(ref.current?.clientHeight);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
    // eslint-disable-next-line
  }, [ref?.current]);

  useEffect(() => {
    // Function to set character limit based on screen width
    const updateCharLimit = () => {
      if (window.innerWidth <= 768) {
        // Tablet and below
        setCharLimit(75);
      } else {
        // Above tablet
        setCharLimit(130);
      }
    };

    // Initialize on mount and listen for resize events
    updateCharLimit();
    window.addEventListener("resize", updateCharLimit);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener("resize", updateCharLimit);
  }, []);

  const shouldShowButton = (status) => {
    return [
      "Peaq Rewards Claimed",
      "Customer Onboarded",
      "Token Purchase Request Accepted",
      "Remaining Amount Request",
      "Remaining Payment Received",
      "Refund Request Processed",
      "Refund Processed",
      "Wallet Whitelist Successfull",
      "Revenue Share Distribution",
      "Token Transfer Completed",
      "Welcome",
      // "Referral Reward",
    ].includes(status);
  };

  const getButtonText = (status) => {
    switch (status) {
      case "Peaq Rewards Claimed":
        return "View Peaq Rewards";
      case "Customer Onboarded":
        return "Invest";
      // case "Referral Reward":
      //   return "View Reward";
      case "Welcome":
        return "Complete KYC";
      case "Token Purchase Request Accepted":
      case "Remaining Amount Request":
      case "Remaining Payment Received":
      case "Refund Request Processed":
        return "View Purchase";
      case "Refund Processed":
        return "View Bank Details";
      case "Wallet Whitelist Successfull":
        return "PNMO Presale";
      case "Revenue Share Distribution":
      case "Token Transfer Completed":
        return "View Portfolio";
      default:
        return null;
    }
  };

  return (
    <div className="">
      <section
        className={`col-span-2 px-4 flex flex-col gap-4 rounded-lg ${window.innerWidth < 768 ? (showAll ? "mb-4" : "mb-0") : showAll ? "mb-4" : "mb-0"}`}
      >
        {sortedNotifications.length === 0 ? (
          <div className="text-center"></div>
        ) : (
          <div className={`flex flex-col gap-4 w-full`}>
            {!showAll && !showAllNotification && (
              <div
                className="relative"
                style={{
                  marginBottom: `${Math.min(sortedNotifications.length, 3) * 8 + 8}px`,
                }}
                // style={{
                //   height:
                //     window.innerWidth < 768
                //       ? userData.kycStatus === "Not Submitted"
                //         ? `${height + 50}px`
                //         : `${height + 2}px`
                //       : `${height + (sortedNotifications.length > 3 ? 36 : sortedNotifications.length > 2 ? 24 : 0)}px`,
                // }}
              >
                {sortedNotifications.slice(0, 3).map((item, index) => (
                  <div
                    key={item._id}
                    onClick={() => setShowAll(sortedNotifications?.length > 1 ? true : false)}
                    className={`notification-card flex border border-monochrome-60 justify-between items-center p-4 mb-4 rounded-lg shadow-lg
                    ${index === 0 ? "relative" : "absolute"} left-1/2 -translate-x-1/2 min-h-[64px] ${
                      showAllNotification || (index === 0 && item.status === "Welcome") ? "" : "h-[64px]"
                    }
                    ${index == 0 ? "w-full cursor-pointer" : index == 1 ? "w-[95%]" : "w-[90%]"}`}
                    ref={index == 0 ? ref : null}
                    style={{
                      zIndex: sortedNotifications.length - index,
                      // top: `${welcomeNotificationIndex === 0 && index > welcomeNotificationIndex ? index * 8 + 28 : index * 8}px`,
                      bottom: `${index * -8}px`,
                    }}
                  >
                    <div className={`w-[85%] flex ${window.innerWidth < 768 ? "items-start" : "items-center"}`}>
                      <IcPercentage className={`w-4 h-4 ${window.innerWidth < 768 ? "mt-2" : ""}`} />
                      <div className="w-full notification-content flex md:items-center flex-grow flex-wrap text-ellipsis ml-3">
                        <div className="flex flex-col md:flex-row md:items-center text-xs">
                          <span className={`text-white ${window.innerWidth < 768 && index > 0 ? "opacity-0" : ""}`}>
                            {index == 0
                              ? item.messages.length > charLimit
                                ? `${item.messages.substring(0, charLimit)}...`
                                : item.messages
                              : item.messages.length > charLimit
                              ? `${item.messages.substring(0, charLimit)}...`
                              : item.messages}
                          </span>
                          <span className={`text-monochrome-20 mt-2 md:mt-0 md:ml-2 ${window.innerWidth < 768 && index > 0 ? "hidden" : ""}`}>
                            {dayjs(item.createdAt).format("DD/MM/YYYY")}
                          </span>
                        </div>
                        {shouldShowButton(item.status) && (
                          <Button
                            rounded
                            classNames={`mt-4 md:mt-0 block md:hidden flex self-start ${window.innerWidth < 768 && index > 0 ? "hidden" : ""}`}
                            primary
                            onClick={() => handleViewDetailClick(item)}
                          >
                            {getButtonText(item.status)}
                          </Button>
                        )}
                      </div>
                    </div>
                    <div className="ml-4 flex flex-row gap-2 justify-center items-center">
                      {shouldShowButton(item.status) && (
                        <Button
                          rounded
                          classNames={`hidden md:block ${window.innerWidth > 768 && index > 0 ? "!hidden" : ""}`}
                          primary
                          onClick={() => handleViewDetailClick(item)}
                        >
                          {getButtonText(item.status)}
                        </Button>
                      )}
                      <IcCross
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDeleteClick(item._id);
                        }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            )}
            {(showAllNotification || showAll) && (
              <div
                className={`flex flex-col rounded-md gap-4 transition-all duration-500 
                ${showAll || showAllNotification ? "animate-slideDown" : "animate-slideUp"}`}
              >
                <div className="flex flex-row justify-between">
                  <h3>All Notifications</h3>
                  <button
                    onClick={() => {
                      setShowAll(false);
                      setShowAllNotifications(false);
                    }}
                    className="flex items-center bg-monochrome-80 rounded-lg"
                  >
                    <span className="text-xs p-2">Show Less</span>
                  </button>
                </div>
                <div
                  className={`flex flex-col gap-2 md:gap-2 
                  ${showAll || showAllNotification ? "max-h-[60vh] lg:max-h-[70vh] overflow-y-scroll" : ""}`}
                >
                  {sortedNotifications.slice(0, showAll || showAllNotification ? sortedNotifications.length : 1).map((item) => (
                    <div
                      key={item._id}
                      style={{
                        borderRadius: "0px !important",
                      }}
                      className="notification-card flex justify-between items-center p-4 mb-4 rounded-lg shadow-lg w-full"
                    >
                      <IcPercentage />
                      <div className="notification-content flex-grow flex-wrap text-ellipsis ml-3">
                        <div className="text-sm flex-wrap">
                          <span className="text-white text-xs">{item.messages}</span>
                          <span className="text-monochrome-20 text-xs ml-2">{dayjs(item.createdAt).format("DD/MM/YYYY")}</span>
                          {shouldShowButton(item.status) && (
                            <Button rounded classNames="block md:hidden flex self-start" primary onClick={() => handleViewDetailClick(item)}>
                              {getButtonText(item.status)}
                            </Button>
                          )}
                        </div>
                      </div>
                      <div className="ml-4 flex flex-row gap-2 justify-center items-center">
                        {shouldShowButton(item.status) && (
                          <Button rounded classNames="hidden md:block" primary onClick={() => handleViewDetailClick(item)}>
                            {getButtonText(item.status)}
                          </Button>
                        )}
                        <IcCross
                          style={{ cursor: "pointer" }}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteClick(item._id);
                          }}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        )}
      </section>
    </div>
  );
};

export default NotificationCard;
