// import React, { useState, Dispatch, SetStateAction, useEffect, useMemo } from "react";
// import { useQuery } from "@tanstack/react-query";
// import useAuth from "@/hooks/useAuth";
// import Loader from "@components/Loader";
// import {
//   // fetchAllProjects, fetchTransactions, fetchTransactionsUsingWalletId, fetchTransactionsUsingUserId,  fetchProjectsInWhichInvestorHasInvested ,
//   fetchUser
// } from "@services/api";
// import TransactionItem from "@/components/Page/Transactions/TransactionItem";
// import { IcNextPage, IcPreviousPage, IcSearch } from "@/assets";
// import { ITransaciton } from "@/types/transactions";
// import { useNetwork } from "@/provider/NetworkContext";
// import DateRangePicker from "@/components/DateRangePicker";
// import StyledDropdown from "@/components/UI/Dropdown/StyledDropdown";

// const transactionCategories = [
//   { value: "all", label: "All Transactions" },
//   // { value: "buy", label: "Buy Transactions" },
//   // { value: "sell", label: "Sell Transactions" },
//   { value: "SECURITY_TOKEN_PURCHASE", label: "Security Token Purchase" },
//   { value: "SECURITY_TOKEN_SALE", label: "Security Token Sale" },
//   { value: "SECURITY_TOKEN_TRANSFER", label: "Security Token Transfer" },
//   { value: "DIVIDEND_PAYMENT", label: "Dividend Payment" },
//   { value: "PAYMENT_TOKEN_TOP_UP", label: "Payment Token Top-Up" },
//   { value: "PAYMENT_TOKEN_WITHDRAW", label: "Payment Token Withdraw" },
// ];

// const perPageItems = [10, 25, 50, 100];

// interface IProject {
//   _id: string;
//   projectName: string;
// }

// interface IError {
//   message: string;
// }
// interface ITransactionsProps {
//   selectedTransaction: ITransaciton | null;
//   setSelectedTransaction: Dispatch<SetStateAction<ITransaciton | null>>;
// }
// const Transactions = ({ selectedTransaction, setSelectedTransaction }: ITransactionsProps) => {
//   const { investorId, userId } = useAuth();
//   const { selectedNetwork } = useNetwork();

//   const [perPageCount, setPerPageCount] = useState<number>(10);
//   const [currentPage, setCurrentPage] = useState<number>(1);
//   const [sortOrder, setSortOrder] = useState<string>("newest");
//   const [searchQuery, setSearchQuery] = useState<string>("");
//   const [dateRange, setDateRange] = useState<string>("currentActivity");
//   const [customDate, setCustomDate] = useState<[Date, Date]>([new Date(), new Date()]);
//   const [selectedProjectSelection, setSelectedProjectSelection] = useState<IProject[]>([]);
//   const [selectedTransactionCategory, setSelectedTransactionCategory] = useState<{ value: string; label: string }[]>([{ value: "all", label: "All Transactions" }]);
//   const [projects, setProjects] = useState<IProject[]>([]);
//   const [error, setError] = useState<IError | null>(null);
//   const [allTransactions, setAllTransactions] = useState<ITransaciton[]>([]);

//   useEffect(() => {
//     const getProjects = async () => {
//       try {
//         // const response = await fetchProjectsInWhichInvestorHasInvested(investorId);
//         const allProjects = Array.isArray(response.data) ? response.data.flat() : response.data;
//         setProjects(allProjects);
//       } catch (error: any) {
//         setError({ message: error.message });
//       }
//     };
//     getProjects();
//   }, [investorId]);

//   const clearAllFilters = () => {
//     setSelectedTransactionCategory([{ value: "all", label: "All Transactions" }]);
//     setSelectedProjectSelection([]);
//   };

//   const {
//     data: userData,
//     isLoading: isLoadingUser,
//     refetch,
//   } = useQuery({
//     queryKey: ["getUser", userId],
//     queryFn: () => fetchUser(userId),
//     enabled: !!userId,
//     select: (data) => data.data,
//   });

//   const {
//     data: transactionsData = [],
//     isLoading: isLoadingTransactions,
//     error: transactionsError,
//     refetch: refetchTransactions,
//   } = useQuery({
//     queryKey: ["getTransactions", investorId, selectedNetwork],
//     // queryFn: () => fetchTransactions(investorId, selectedNetwork),
//     enabled: !!investorId && !!selectedNetwork,
//     select: (data) => data.data ?? [],
//   });

//   const {
//     data: transactionsUsingWalletIdData = [],
//     isLoading: isLoadingTransactionsUsingWalletId,
//     error: transactionsError2,
//     refetch: refetchTransactionsUsingWalletId,
//   } = useQuery({
//     queryKey: ["getTransactionsUsingWalletId", userData?.walletDetails?.web3authWalletAddress],
//     // queryFn: () => fetchTransactionsUsingWalletId(userData?.walletDetails?.web3authWalletAddress),
//     // queryFn: () => fetchTransactionsUsingWalletId("0xd41E44D8a618BbE843Ff689C47DCeC7A33181F04"),
//     enabled: !!userData,
//     select: (data) => data.data ?? [],
//   });

//   const {
//     data: transactionsUsingUserIdData = [],
//     isLoading: isLoadingTransactionsUsingUserId,
//     error: transactionsError3,
//     refetch: refetchTransactionsUsingUserId,
//   } = useQuery({
//     queryKey: ["getTransactionsUsingUserId", userId],
//     // queryFn: () => fetchTransactionsUsingUserId(userId),
//     // queryFn: () => fetchTransactionsUsingUserId("66e057713ad854af4ba58fa0"),
//     enabled: !!userId,
//     select: (data) => data.data ?? [],
//   });

//   // useEffect(() => {
//   //   const transactionsUsingWalletIdTransactions = transactionsUsingWalletIdData.transactions?.length > 0 ? transactionsUsingWalletIdData.transactions : [];
//   //   const transactionsUsingUserIdTransactions = transactionsUsingUserIdData.transactions?.length > 0 ? transactionsUsingUserIdData.transactions : [];

//   //   setAllTransactions(transactionsData.concat(transactionsUsingWalletIdTransactions, transactionsUsingUserIdTransactions));
//   // }, [transactionsData, transactionsUsingWalletIdData, transactionsUsingUserIdData]);

//   const sortedTransactions = useMemo(() => {
//     if (!allTransactions?.length) return [];
//     // return allTransactions.sort((a: any, b: any) => {
//     //   const dateA = a.date ? new Date(a.date).getTime() : 0;
//     //   const dateB = b.date ? new Date(b.date).getTime() : 0;
//     //   return sortOrder === "newest" ? dateB - dateA : dateA - dateB;
//     // });
//     return allTransactions
//       .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
//     // .slice(0, 5);

//   }, [allTransactions]);

//   const totalPages = useMemo(() => {
//     return Math.ceil(sortedTransactions.length / perPageCount);
//   }, [sortedTransactions.length, perPageCount]);

//   const displayedTransactions = useMemo(() => {
//     const startIndex = (currentPage - 1) * perPageCount;
//     return sortedTransactions.slice(startIndex, startIndex + perPageCount);
//   }, [sortedTransactions, currentPage, perPageCount]);

//   const filteredTransactions = useMemo(() => {
//     if (!displayedTransactions || displayedTransactions.length === 0) return [];
//     const searchText = searchQuery.toLowerCase();
//     const currentDate = new Date();

//     return displayedTransactions.filter((transaction: any) => {
//       const transactionDate = new Date(transaction?.updatedAt || transaction?.createdAt);

//       const matchesSearch =
//         transaction?.updatedAt?.toLowerCase().includes(searchText) ||
//         transaction?.transactionType?.toLowerCase().includes(searchText) ||
//         transaction?.tokenSymbol?.toLowerCase().includes(searchText) ||
//         transaction?.tokenAmount?.toString().toLowerCase().includes(searchText);

//       const matchesCategory =
//         selectedTransactionCategory.length === 0 || // Show all transactions if no category is selected
//         selectedTransactionCategory.some((category: any) => {
//           if (category.value === "all") {
//             return true; // Include all transactions if "all" is selected
//           }
//           if (category.value === "buy" && transaction?.fromUserId === userId) {
//             return true; // Include transactions where the current user is the sender
//           }
//           if (category.value === "sell" && transaction?.toUserId === userId) {
//             return true; // Include transactions where the current user is the receiver
//           }
//           if (
//             transaction?.transactionPurpose == category.value ||
//             (category.value == "SECURITY_TOKEN_TRANSFER" && transaction?.transactionType == "token transaction")
//           ) {
//             return true; // Include transactions matching the selected transactionPurpose
//           }
//           return false;
//         });

//       const matchesProject =
//         selectedProjectSelection.length === 0 || selectedProjectSelection.some((project: any) => transaction?.tokenId?.projectId?._id == project.value);

//       let matchesDateRange = true;

//       if (dateRange === "custom") {
//         if (customDate && customDate.length === 2) {
//           const [start, end] = customDate;
//           matchesDateRange = transactionDate >= start && transactionDate <= end;
//         } else {
//           matchesDateRange = false;
//         }
//       } else if (dateRange === "currentActivity") {
//         const last24Hours = new Date();
//         last24Hours.setHours(currentDate.getHours() - 24);
//         matchesDateRange = transactionDate >= last24Hours && transactionDate <= currentDate;
//       } else if (dateRange === "thisWeek") {
//         const startOfWeek = new Date(currentDate);
//         startOfWeek.setDate(currentDate.getDate() - currentDate.getDay());
//         startOfWeek.setHours(0, 0, 0, 0);
//         matchesDateRange = transactionDate >= startOfWeek && transactionDate <= currentDate;
//       } else if (dateRange === "lastWeek") {
//         const startOfLastWeek = new Date(currentDate);
//         startOfLastWeek.setDate(currentDate.getDate() - currentDate.getDay() - 7);
//         startOfLastWeek.setHours(0, 0, 0, 0);
//         const endOfLastWeek = new Date(startOfLastWeek);
//         endOfLastWeek.setDate(startOfLastWeek.getDate() + 6);
//         endOfLastWeek.setHours(23, 59, 59, 999);
//         matchesDateRange = transactionDate >= startOfLastWeek && transactionDate <= endOfLastWeek;
//       } else if (dateRange === "thisMonth") {
//         const startOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
//         const endOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
//         matchesDateRange = transactionDate >= startOfMonth && transactionDate <= endOfMonth;
//       } else if (dateRange === "previousMonth1") {
//         const startOfPreviousMonth1 = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
//         const endOfPreviousMonth1 = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);
//         endOfPreviousMonth1.setHours(23, 59, 59, 999); // Include the full last day
//         matchesDateRange = transactionDate >= startOfPreviousMonth1 && transactionDate <= endOfPreviousMonth1;
//       } else if (dateRange === "previousMonth2") {
//         const startOfPreviousMonth2 = new Date(currentDate.getFullYear(), currentDate.getMonth() - 2, 1);
//         const endOfPreviousMonth2 = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 0);
//         endOfPreviousMonth2.setHours(23, 59, 59, 999); // Include the full last day
//         matchesDateRange = transactionDate >= startOfPreviousMonth2 && transactionDate <= endOfPreviousMonth2;
//       } else if (dateRange === "previousMonth3") {
//         const startOfPreviousMonth3 = new Date(currentDate.getFullYear(), currentDate.getMonth() - 3, 1);
//         const endOfPreviousMonth3 = new Date(currentDate.getFullYear(), currentDate.getMonth() - 2, 0);
//         endOfPreviousMonth3.setHours(23, 59, 59, 999); // Include the full last day
//         matchesDateRange = transactionDate >= startOfPreviousMonth3 && transactionDate <= endOfPreviousMonth3;
//       }

//       return matchesSearch && matchesCategory && matchesProject && matchesDateRange;
//     });
//     // eslint-disable-next-line
//   }, [displayedTransactions, searchQuery, selectedTransactionCategory, selectedProjectSelection, dateRange, customDate]);

//   if (isLoadingTransactions || isLoadingTransactionsUsingWalletId || isLoadingTransactionsUsingUserId) {
//     // return <Loader isLoading={true} />;
//     return (
//       <div className="flex items-center justify-center min-h-screen">
//         <Loader isLoading={true} />
//       </div>
//     );
//   }

//   // if (transactionsError || transactionsError2 || transactionsError3) {
//   //   return <div>Error loading transactions.</div>;
//   // }

//   const handleRemoveCategory = (categoryToRemove: any) => {
//     setSelectedTransactionCategory((prevCategories) => prevCategories.filter((category: any) => category.value !== categoryToRemove.value));
//   };

//   const handleRemovePlant = (plantToRemove: any) => {
//     setSelectedProjectSelection((prevPlants) => prevPlants.filter((plant: any) => plant._id !== plantToRemove._id));
//   };

//   const handleDownloadCSV = () => {
//     const csvData = [
//       ["Date", "Time", "Type", "Symbols", "Amount"],
//       ...filteredTransactions.map((transaction: any) => [transaction.date, transaction.transactionType, transaction.tokenSymbol, transaction.tokenAmount]),
//     ];
//     const csvContent = "data:text/csv;charset=utf-8," + csvData.map((e) => e.join(",")).join("\n");
//     const encodedUri = encodeURI(csvContent);
//     const link = document.createElement("a");
//     link.setAttribute("href", encodedUri);
//     link.setAttribute("download", "transactions.csv");
//     document.body.appendChild(link);
//     link.click();
//     document.body.removeChild(link);
//   };

//   return (
//     <div className="transactions-page">
//       <div className="mb-4 flex flex-col xl:flex-row justify-between xl:gap-4 relative">
//         <div className="flex flex-col xl:w-3/5 w-full gap-4">
//           <div className="flex gap-4 flex-col md:flex-row">
//             <div className="flex flex-col">
//               <label className="text-xs font-medium text-white pb-1">Transaction Period</label>
//               <DateRangePicker dateRange={dateRange} setDateRange={setDateRange} customDate={customDate} setCustomDate={setCustomDate} />
//             </div>
//             <div className="w-full lg:w-auto flex md:flex-row gap-4 flex-col">
//               <div className="flex flex-col">
//                 <label className="text-xs font-medium text-white pb-1">Transaction Category</label>
//                 <StyledDropdown
//                   options={transactionCategories}
//                   onChange={(selectedValues) => setSelectedTransactionCategory(selectedValues)}
//                   value={selectedTransactionCategory}
//                   isSearchable={false}
//                   isMulti={true}
//                   placeholder="Select transactions"
//                 />
//               </div>

//               <div className="flex flex-col">
//                 <label className="text-xs font-medium text-white pb-1">Project</label>
//                 <StyledDropdown
//                   options={projects.map((project: any) => ({ value: project.projectId, label: project.projectName }))}
//                   onChange={(selectedValues) => setSelectedProjectSelection(selectedValues)}
//                   value={selectedProjectSelection}
//                   isSearchable={true}
//                   isMulti={true}
//                   placeholder="Select Projects"
//                 />
//               </div>
//             </div>
//           </div>
//           <div className="flex justify-start items-center flex-wrap gap-5">
//             {selectedTransactionCategory.length > 0 &&
//               selectedTransactionCategory.map((category: any, index: number) => (
//                 <span key={index} className="border border-green-500 bg-[#243c3f] text-white px-2 py-1 rounded-lg flex items-center gap-2 text-xs">
//                   {category.label}
//                   <button className="text-white hover:text-green-500 text-sm" onClick={() => handleRemoveCategory(category)}>
//                     ✕
//                   </button>
//                 </span>
//               ))}

//             {selectedProjectSelection.length > 0 &&
//               selectedProjectSelection.map((plant: any, index: number) => (
//                 <span key={index} className="border border-green-500 bg-[#243c3f] text-white px-2 py-1 rounded-lg flex items-center gap-2 text-xs">
//                   {plant.label}
//                   <button className="text-white hover:text-green-500" onClick={() => handleRemovePlant(plant)}>
//                     ✕
//                   </button>
//                 </span>
//               ))}

//             {(selectedTransactionCategory.length > 0 || selectedProjectSelection.length > 0) && (
//               <button className="flex items-center gap-2 text-white underline text-xs" onClick={clearAllFilters}>
//                 <span className="text-white text-xs">× Clear all filters</span>
//               </button>
//             )}
//           </div>
//         </div>
//         {/* <button
//           className={`flex items-center md:justify-center py-[12px] max-w-max px-6 text-sm bg-[#1A1C22] border-none rounded-lg text-white max-h-max ${
//             selectedProjectSelection.length > 0 || selectedTransactionCategory.length > 0 ? "lg:absolute lg:bottom-0 xl:mt-0 mt-5 lg:right-4" : "xl:mt-5"
//           }`}
//           onClick={handleDownloadCSV}
//         >
//           Download CSV
//         </button> */}
//       </div>

//       <div className="min-h-[650px] lg:h-[80vh] overflow-x-auto sm:overflow-hidden rounded-lg bg-monochrome-100">
//         <div className="relative flex flex-col min-h-[650px] lg:h-full w-[200%] sm:w-full">
//           <div className="text-[0.688rem] py-[10px] px-4 grid grid-cols-5 border-b border-monochrome-40 text-monochrome-20">
//             <span>Date</span>
//             <span>Type</span>
//             <span>Symbols</span>
//             <span>Time</span>
//             <span>Amount</span>
//           </div>

//           <div className="overflow-y-auto grow flex items-center justify-center">
//             {filteredTransactions && filteredTransactions.length > 0 ? (
//               filteredTransactions.map((transaction: any, index: number) => (
//                 <TransactionItem
//                   key={transaction?._id}
//                   item={transaction}
//                   onClick={() => setSelectedTransaction(transaction)}
//                   isSelected={selectedTransaction?._id === transaction?._id}
//                   uniqueId={`transaction-${transaction?._id}`}
//                   currentUserId={userId}
//                   currentWalletAddress={userData.walletDetails.web3authWalletAddress}
//                 />
//               ))
//             ) : (
//               <div className="text-lg">No transactions.</div>
//             )}
//           </div>

//           <div className="w-full flex justify-between py-[14px] text-[14px] px-4">
//             <div className="flex gap-3 items-center">
//               {/* <ul className="flex">
//                 {perPageItems.map((item: number, index: number) => (
//                   <li
//                     key={"page-items-" + index}
//                     onClick={() => {
//                       setPerPageCount(item);
//                       setCurrentPage(1);
//                     }}
//                     className={`py-2 px-3 border-2 border-monochrome-20  first:rounded-l-lg last:rounded-r-lg  cursor-pointer bg-monochrome-60 text-monochrome-20 ${
//                       item == perPageCount ? "!border-green-500 !bg-green-10 !text-green-500 " : ""
//                     }`}
//                   >
//                     {item}
//                   </li>
//                 ))}
//               </ul>
//               <span className="text-monochrome-20">Items per page</span> */}
//             </div>
//             {/* <div className="flex items-center gap-2">
//               <span className="text-monochrome-20">Page</span>
//               <input
//                 className="!bg-monochrome-60 !text-monochrome-20 !w-[40px] text-center !py-1 !px-0 rounded-lg !m-0 !min-h-[auto]"
//                 type="text"
//                 value={currentPage}
//                 min={1}
//                 onChange={(e) => setCurrentPage(Number(e.target.value))}
//               />
//               <span className="text-monochrome-20">of {totalPages}</span>
//               <div className="grid grid-cols-2 border-2 border-monochrome-20 divide-monochrome-20 divide-x rounded-lg items-center">
//                 <button
//                   className="p-2 bg-monochrome-60 rounded-l-lg"
//                   onClick={() => setCurrentPage(currentPage > 1 ? currentPage - 1 : 1)}
//                   disabled={currentPage === 1}
//                 >
//                   <IcPreviousPage />
//                 </button>
//                 <button
//                   className="p-2 bg-monochrome-60 rounded-r-lg"
//                   onClick={() => setCurrentPage(currentPage < totalPages ? currentPage + 1 : totalPages)}
//                   disabled={currentPage === totalPages}
//                 >
//                   <IcNextPage />
//                 </button>
//               </div>
//             </div> */}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Transactions;

import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import useAuth from "@/hooks/useAuth";
import { fetchUser } from "@services/api";
import IcBuy from "@/assets/ic_buy";
import DateRangePicker from "@/components/DateRangePicker";
import StyledDropdown from "@/components/UI/Dropdown/StyledDropdown";
import LogoLoader from "@/components/LogoLoader";

const transactionCategories = [
  { value: "all", label: "All Transactions" },
  { value: "SECURITY_TOKEN_PURCHASE", label: "Security Token Purchase" },
  { value: "SECURITY_TOKEN_SALE", label: "Security Token Sale" },
  { value: "SECURITY_TOKEN_TRANSFER", label: "Security Token Transfer" },
  { value: "DIVIDEND_PAYMENT", label: "Dividend Payment" },
  { value: "PAYMENT_TOKEN_TOP_UP", label: "Payment Token Top-Up" },
  { value: "PAYMENT_TOKEN_WITHDRAW", label: "Payment Token Withdraw" },
];

interface PrnmoTransaction {
  allocation: number;
  timestamp: string;
  txHash: string;
  _id: string;
}

const TransactionItem: React.FC<{ transaction: PrnmoTransaction }> = ({ transaction }) => {
  const handleClick = () => {
    window.open(`https://bscscan.com/tx/${transaction.txHash}`, "_blank");
  };

  return (
    <div className="text-[0.688rem] py-[10px] px-4 grid grid-cols-4 border-b border-monochrome-40 hover:bg-monochrome-40 cursor-pointer" onClick={handleClick}>
      <span className="flex items-center gap-2">
        <IcBuy />
        Buy
      </span>
      <span>PRNMO</span>
      {/* <span>{new Date(transaction.timestamp).toLocaleTimeString()}</span> */}
      <span className="text-green-500">+{transaction.allocation}</span>
      <span className="text-green-500">${transaction.allocation * 0.0075}</span>
    </div>
  );
};

const Transactions = () => {
  const { userId } = useAuth();
  const [dateRange, setDateRange] = useState<string>("currentActivity");
  const [customDate, setCustomDate] = useState<[Date, Date]>([new Date(), new Date()]);
  const [selectedTransactionCategory, setSelectedTransactionCategory] = useState<{ value: string; label: string }[]>([
    { value: "all", label: "All Transactions" },
  ]);
  const [selectedProjectSelection, setSelectedProjectSelection] = useState<any[]>([]);

  const { data: userData, isLoading } = useQuery({
    queryKey: ["getUser", userId],
    queryFn: () => fetchUser(userId),
    enabled: !!userId,
    select: (data) => data.data,
  });

  const clearAllFilters = () => {
    setSelectedTransactionCategory([{ value: "all", label: "All Transactions" }]);
    setSelectedProjectSelection([]);
  };

  const removeCategoryFilter = (category) => {
    setSelectedTransactionCategory(selectedTransactionCategory.filter((f) => f.value !== category.value));
  };

  return (
    <div className="transactions-page">
      {isLoading ? (
        <div className="min-h-[40px] flex align-center justify-center">
          <LogoLoader isLoading={true} />
        </div>
      ) : null}
      <div className="mb-4 flex flex-col xl:flex-row justify-between xl:gap-4 relative">
        <div className="flex flex-col xl:w-3/5 w-full gap-4">
          <div className="flex gap-4 flex-col md:flex-row">
            <div className="flex flex-col">
              <label className="text-xs font-medium text-white pb-1">Transaction Period</label>
              <DateRangePicker dateRange={dateRange} setDateRange={setDateRange} customDate={customDate} setCustomDate={setCustomDate} />
            </div>
            <div className="w-full lg:w-auto flex md:flex-row gap-4 flex-col">
              <div className="flex flex-col">
                <label className="text-xs font-medium text-white pb-1">Transaction Category</label>
                <StyledDropdown
                  options={transactionCategories}
                  onChange={(selectedValues) => setSelectedTransactionCategory(selectedValues)}
                  value={selectedTransactionCategory}
                  isSearchable={false}
                  isMulti={true}
                  placeholder="Select transactions"
                />
              </div>
              <div className="flex flex-col">
                <label className="text-xs font-medium text-white pb-1">Project</label>
                <StyledDropdown
                  options={[]}
                  onChange={(selectedValues) => setSelectedProjectSelection(selectedValues)}
                  value={selectedProjectSelection}
                  isSearchable={true}
                  isMulti={true}
                  placeholder="Select Projects"
                />
              </div>
            </div>
          </div>

          <div className="flex justify-start items-center flex-wrap gap-5">
            {selectedTransactionCategory.length > 0 &&
              selectedTransactionCategory.map((category: any, index: number) => (
                <span key={index} className="border border-green-500 bg-[#243c3f] text-white px-2 py-1 rounded-lg flex items-center gap-2 text-xs">
                  {category.label}
                  <button className="text-white hover:text-green-500 text-sm" onClick={() => removeCategoryFilter(category)}>
                    ✕
                  </button>
                </span>
              ))}

            {(selectedTransactionCategory.length > 0 || selectedProjectSelection.length > 0) && (
              <button className="flex items-center gap-2 text-white underline text-xs" onClick={clearAllFilters}>
                <span className="text-white text-xs">× Clear all filters</span>
              </button>
            )}
          </div>
        </div>
      </div>

      <div className="min-h-[650px] lg:h-[80vh] overflow-x-auto sm:overflow-hidden rounded-lg bg-monochrome-100">
        <div className="relative flex flex-col min-h-[650px] lg:h-full w-[200%] sm:w-full">
          <div className="text-[0.688rem] py-[10px] px-4 grid grid-cols-4 border-b border-monochrome-40 text-monochrome-20">
            {/* <span>Date</span> */}
            <span>Type</span>
            <span>Symbols</span>
            {/* <span>Time</span> */}
            <span>Amount</span>
            <span>Value</span>
          </div>

          <div className="overflow-y-auto grow">
            {userData?.prnmoTransactions?.length > 0 ? (
              userData.prnmoTransactions.map((transaction) => <TransactionItem key={transaction._id} transaction={transaction} />)
            ) : (
              <div className="flex items-center justify-center h-full text-lg">No transactions.</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Transactions;
