const IcAcArrowUp = (props: any) => {
  return (
    <div {...props}>
      <svg width="20" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11 9L8 6L5 9" stroke="#808080" strokeWidth="1.75" strokeLinecap="round" /> {/* Up arrow */}
      </svg>
    </div>
  );
};

export default IcAcArrowUp;
