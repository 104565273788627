import className from "classnames";
interface ButtonProps {
  children?: React.ReactNode;
  primary?: boolean;
  transparent?: boolean;
  secondary?: boolean;
  onClick?: () => void;
  rounded?: boolean;
  classNames?: string;
  disabled?: boolean;
  type?: "button" | "submit" | "reset" | undefined;
}
const Button: React.FC<ButtonProps> = ({
  children,
  primary = false,
  transparent,
  secondary,
  onClick,
  rounded,
  classNames,
  disabled,
  type = "button",
}: ButtonProps) => {
  const classes = className(`py-2 px-2 md:px-6 flex flex-row items-center cursor-pointer justify-center gap-2 ${classNames ? classNames : ""}`, {
    "bg-monochrome-60 text-white text-sm border-[1px] border-monochrome-40": primary,
    "bg-green-500 min-h-[40px] hover:bg-white": secondary && !disabled,
    "bg-green-800 min-h-[40px]": secondary && disabled, // Darker shade when disabled
    "p-0 gap-0 bg-transparent": transparent,
    "rounded-[8px]": rounded,
    "opacity-50 cursor-not-allowed": disabled,
  });

  return (
    <button className={classes} type={type} onClick={onClick} disabled={disabled}>
      {children}
    </button>
  );
};

export default Button;
