import IcEdit from "@/assets/ic_edit";
import IcMergeCopy from "@/assets/ic_merge_copy";
import KycVerificationBanner from "@/components/KycVerificationBanner";
import Loader from "@/components/Loader";
import useAuth from "@/hooks/useAuth";
import useSocketIO from "@/hooks/useSocketIO";
import { deleteUser, fetchUser, updateUser } from "@/services/api";
import useStore from "@/store/useStore";
import { useQuery } from "@tanstack/react-query";
import { useContext, useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import toast from "react-hot-toast";
import { z } from "zod";
import KycStatus from "./KycStatus";
import Button from "@/components/UI/Button";
import { useWeb3Auth } from "@/services/Web3AuthService";
import { AuthContext } from "@/provider/AuthContext";
import { useNavigate } from "react-router-dom";
import { Modal } from "flowbite-react";
import IcCross from "@/assets/ic_cross";
import LogoLoader from "@/components/LogoLoader";

const userDataSchema = z.object({
  firstName: z.string().min(1, "First name is required"),
  lastName: z.string().min(1, "Last name is required"),
  email: z.string().email("Invalid email format"),
});

const AccountDetails = () => {
  const { setUser, investorData } = useStore();
  const { userId } = useAuth();

  const {
    data: userData,
    isLoading: isLoadingUser,
    refetch,
  } = useQuery({
    queryKey: ["getUser", userId],
    queryFn: () => fetchUser(userId),
    enabled: !!userId,
    select: (data) => data.data,
  });

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });

  const [initialFormData, setInitialFormData] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [kycStatus, setKycStatus] = useState<string>("Not Submitted");

  const [isDeleteAccountModalOpen, setDeleteAccountModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  useSocketIO(userId, (data) => {
    if (data.kycStatus) {
      setKycStatus(data.kycStatus); // Update the kycStatus state dynamically
      setFormData((prevFormData) => ({
        ...prevFormData,
        firstName: data.firstName,
        lastName: data.lastName,
      }));
      toast.success(`KYC status updated to ${data.kycStatus}`);
    } else {
      toast.error(data.message);
    }
  });

  const isVerified = kycStatus === "Verified";

  useEffect(() => {
    if (userData) {
      const _userData = {
        firstName: userData.firstName || "",
        lastName: userData.lastName || "",
        email: userData.email || "",
      };
      setKycStatus(userData.kycStatus);
      setFormData(_userData as any);
      setInitialFormData(_userData);
    }
  }, [userData]);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const result = userDataSchema.safeParse(formData);
    if (!result.success) {
      toast.error("Please correct the errors before submitting.");
      return;
    }

    try {
      setIsSubmitting(true);
      const response: any = await updateUser(userId, formData);
      setIsSubmitting(false);
      if (response.code == 200) {
        setFirstNameFieldEditable(false);
        setLastNameFieldEditable(false);
        setUser({
          ...response.data,
          walletDetails: userData?.walletDetails,
        });
        toast.success("User details updated successfully");
        setInitialFormData(formData);
      } else {
        toast.error("Failed to update user details.");
      }
    } catch (error) {
      setIsSubmitting(false);
      console.error("Error updating user details:", error);
      toast.error("Failed to update user details.");
    }
  };

  const showCopiedToast = (message: string) => {
    toast.success(message);
  };

  const [isFirstNameFieldEditable, setFirstNameFieldEditable] = useState(false);
  const [isLastNameFieldEditable, setLastNameFieldEditable] = useState(false);

  const { web3auth } = useWeb3Auth();
  const { logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const { reset } = useStore();

  const handleDelete = () => {
    setLoading(true);
    deleteUser(userData?._id)
      .then((res: any) => {
        if (res.code == 200) {
          toast.success("User deleted successfully");
          handleLogout();
        } else {
          toast.error("Failed to delete user");
        }
      })
      .catch((err) => {
        console.error("Error deleting user:", err);
        toast.error("Failed to delete user");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleLogout = async () => {
    try {
      if (web3auth.connected) {
        // Check if the user is connected before logging out
        await web3auth.logout();
      }
    } catch (error) {
      console.error("Web3Auth logout error:", error);
    }

    logout(); // Log out from your application
    reset(); // reset store data
    localStorage.removeItem("authToken");
    localStorage.removeItem("web3AuthToken");
    navigate("/"); // Redirect to the home page
    window.location.reload(); //ensure a fresh state
  };

  return (
    <div className="flex flex-col gap-4">
      {isLoadingUser ? (
        <div className="min-h-[40px] flex align-center justify-center">
          <LogoLoader isLoading={true} />
        </div>
      ) : null}
      <h4 className="text-monochrome-20">Account</h4>
      <div className="flex flex-col sm:flex-row bg-monochrome-100 p-4 rounded divide-y sm:divide-y-0 sm:divide-x divide-monochrome-40">
        <div className="flex flex-col gap-2 sm:w-4/12 pb-4 md:pb-0">
          <h3 className="text-[0.875rem]">Basic Details</h3>
          <p className="text-monochrome-20 text-[0.688rem]">Manage essential account information</p>
        </div>
        <div className="flex flex-col gap-4 sm:pl-6 sm:w-8/12 mobile-space">
          <div className="relative flex flex-col gap-3">
            <label className="text-[0.688rem]">First Name</label>
            <input
              type="text"
              name="firstName"
              placeholder="Enter your first name here"
              className={`w-full px-0 placeholder-[#9E9E9E]  !rounded-none ${
                isFirstNameFieldEditable ? "px-2" : "bg-transparent !border-b border-monochrome-40"
              }`}
              disabled={!isFirstNameFieldEditable}
              value={formData.firstName}
              onChange={handleChange}
            />
            {isFirstNameFieldEditable ? (
              <div
                className="absolute top-[45px] right-2 cursor-pointer text-green-500"
                onClick={(e) => {
                  handleSubmit(e);
                }}
              >
                {isSubmitting ? <Loader isLoading={true} /> : "Save"}
              </div>
            ) : (
              <div
                className={`${userData?.kycStatus == "Verified" ? "hidden" : ""} absolute top-[38px] right-2 cursor-pointer text-green-500`}
                onClick={() => setFirstNameFieldEditable(true)}
              >
                <IcEdit />
              </div>
            )}
          </div>
          <div className="relative flex flex-col gap-3">
            <label className="text-[0.688rem]">Last Name</label>
            <input
              type="text"
              name="lastName"
              placeholder="Enter your last name here"
              className={`w-full px-0 placeholder-[#9E9E9E] !rounded-none ${
                isLastNameFieldEditable ? "px-2" : "bg-transparent !border-b border-monochrome-40"
              }`}
              disabled={!isLastNameFieldEditable}
              value={formData.lastName}
              onChange={handleChange}
            />
            {isLastNameFieldEditable ? (
              <div
                className="absolute top-[45px] right-2 cursor-pointer text-green-500"
                onClick={(e) => {
                  handleSubmit(e);
                }}
              >
                {isSubmitting ? <Loader isLoading={true} /> : "Save"}
              </div>
            ) : (
              <div
                className={`${userData?.kycStatus == "Verified" ? "hidden" : ""} absolute top-[38px] right-2 cursor-pointer text-green-500`}
                onClick={() => setLastNameFieldEditable(true)}
              >
                <IcEdit />
              </div>
            )}
          </div>
          <div className="flex flex-col gap-1">
            <label className="text-[0.688rem]">Email</label>
            <input type="email" placeholder="Enter your email here" value={formData.email} disabled className="w-full bg-transparent !text-monochrome-20 p-0" />
          </div>
          <div className="relative flex flex-col gap-3">
            <label className="mt-3 text-[1rem]">KYC Status</label>
            <KycStatus status={kycStatus} />
          </div>
        </div>
      </div>
      <div className="flex flex-col md:flex-row bg-monochrome-100 p-4 rounded divide-y md:divide-y-0 md:divide-x divide-monochrome-40">
        <div className="flex flex-col gap-2 md:w-4/12 pb-4 md:pb-0">
          <h3 className="text-[0.875rem]">Wallet Details</h3>
          {/* <p className="text-monochrome-20 text-[0.688rem]">Your wallet address</p> */}
        </div>
        <div className="flex flex-col gap-2 md:w-8/12 pb-4 md:pb-0">
          <div className="flex flex-col md:pl-6 md:w-full pt-4 md:pt-0">
            <div className="flex items-center gap-4 md:mt-2 justify-between">
              <label className="text-monochrome-20 text-[0.688rem]">Penomo Platform Wallet Address</label>
              {/* <div className=""><LabelWithTooltip label="" tooltip="This is your penomo wallet address, which has been automatically assigned to your email address." /></div> */}
            </div>
            <div className="flex items-center gap-4 md:mt-2 justify-start">
              <p className="text break-words text-slate-300 text-[0.688rem] w-[320px]">{userData?.walletDetails?.web3authWalletAddress}</p>
              <CopyToClipboard text={userData?.walletDetails?.web3authWalletAddress || ""} onCopy={() => showCopiedToast("Address copied to clipboard!")}>
                <IcMergeCopy className="text-monochrome-20 cursor-pointer" />
              </CopyToClipboard>
            </div>
          </div>
        </div>
      </div>
      {userData?.referral_code && investorData?.investorType === "Retail" && (
        <div className="flex flex-col md:flex-row bg-monochrome-100 p-4 rounded divide-y md:divide-y-0 md:divide-x divide-monochrome-40">
          <div className="flex flex-col gap-2 md:w-4/12 pb-4 md:pb-0">
            <h3 className="text-[0.875rem]">Referral Link</h3>
            <p className="text-monochrome-20 text-[0.688rem]">Share this link to invite friends</p>
          </div>
          <div className="flex flex-col md:pl-6 md:w-8/12">
            <label className="text-monochrome-20 text-[0.688rem]">Referral Link</label>
            <div className="flex items-center gap-4 md:mt-2 justify-start">
              <p className="text break-words text-slate-300 text-[0.688rem] w-[320px]">
                {process.env.REACT_APP_FRONTEND_URL_LOCAL + "?ref=" + userData?.referral_code}
              </p>
              <CopyToClipboard
                text={process.env.REACT_APP_FRONTEND_URL_LOCAL + "?ref=" + userData?.referral_code || ""}
                onCopy={() => toast.success("Referral link copied to clipboard!")}
              >
                <IcMergeCopy className="text-monochrome-20 cursor-pointer" />
              </CopyToClipboard>
            </div>
          </div>
        </div>
      )}
      <div>
        <KycVerificationBanner isVerified={isVerified} />
      </div>

      <div>
        <Button
          classNames="md:w-[250px] lg:w-[200px] xl:w-auto !text-[0.729rem] font-normal mx-auto !border-danger-100"
          primary={true}
          rounded={true}
          onClick={() => setDeleteAccountModalOpen(true)}
        >
          Delete Account
        </Button>
      </div>

      <Modal
        show={isDeleteAccountModalOpen}
        onClose={() => {
          setDeleteAccountModalOpen(false);
        }}
        position={"center"}
        size={"2xl"}
      >
        <Modal.Body className="p-6 bg-monochrome-100">
          <div className={"flex justify-between items-center pb-5"}>
            <h2 className="mb-0 font-semibold">Delete Account</h2>
            <Button
              classNames="w-[32px] bg-monochrome-60 border border-monochrome-40 !px-2 !rounded-xl"
              rounded
              onClick={() => {
                setDeleteAccountModalOpen(false);
              }}
            >
              <IcCross />
            </Button>
          </div>

          <hr className="border-monochrome-40" />

          <section className="pt-4">
            <p className="text-md">Are you sure you want to delete your account? This action cannot be undone.</p>
            <div className="flex justify-end gap-4 pt-4">
              <Button onClick={() => setDeleteAccountModalOpen(false)} disabled={loading} primary classNames="flex rounded-lg font-normal">
                Cancel
              </Button>
              <Button onClick={handleDelete} disabled={loading} secondary classNames="flex rounded-lg font-medium bg-danger-100">
                {loading ? <Loader isLoading={true} /> : "Delete"}
              </Button>
            </div>
          </section>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AccountDetails;
