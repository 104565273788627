import KycVerificationBanner from "@/components/KycVerificationBanner";
import PeaqRewardingCampaign from "@/components/Page/Dashboard/PeaqRewardBanner";
import RawTokenList from "@/components/Page/RAWToken/RawTokenList";
import RecentTransactions from "@/components/Page/RecentTransactions/RecentTransactions";
import PenomoPreSale from "@/components/PenomoPreSaleBanner";
import useAuth from "@/hooks/useAuth";
import { ITransaciton } from "@/types/transactions";
import BalanceCard from "@components/BalanceCard";
import Loader from "@components/Loader";
import PenomoFlipCards from "@components/PenomoRewards";
import {
  fetchInvestor,
  fetchUser,
  //  getTokenPurchaseRequests
} from "@services/api";
import useStore from "@store/useStore";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import useSocketIO from "@/hooks/useSocketIO";
import toast from "react-hot-toast";
import PurchaseComponent from "@/components/Page/Dashboard/PurchaseComponent";
import PaymentTokenModal from "@/components/Page/Dashboard/PaymentToken";
import { Modal } from "flowbite-react";
import Button from "@/components/UI/Button";
import IcCross from "@/assets/ic_cross";
import { IcChecked, IcWarning } from "@/assets";
import LogoLoader from "@/components/LogoLoader";

interface ITransactionsProps {
  selectedTransaction: ITransaciton | null;
  setSelectedTransaction: Dispatch<SetStateAction<ITransaciton | null>>;
}

// List of restricted countries by their short codes
const restrictedCountries = [
  "US",
  "USA", // United States
  "CN",
  "CHN", // China
  "KP",
  "PRK", // North Korea
  "IR",
  "IRN", // Iran
  "AF",
  "AFG", // Afghanistan
  "SY",
  "SYR", // Syria
  "CU",
  "CUB", // Cuba
  "DZ",
  "DZA", // Algeria
  "EG",
  "EGY", // Egypt
  "MA",
  "MAR", // Morocco
];
const DashboardPage = (props: ITransactionsProps) => {
  const { userId, investorId } = useAuth();
  const { setUser, setInvestor, userData: storeUserData } = useStore();
  const queryClient = useQueryClient();
  const [kycStatus, setKycStatus] = useState<string>("Not Submitted");
  const [show, setShow] = useState(false); // temporary state for purchase modal
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorModalContent, setErrorModalContent] = useState({
    title: "",
    message: "",
  });

  // temp func
  const handlenModal = () => {
    setShow((prev) => !prev);
  };

  useEffect(() => {
    queryClient?.invalidateQueries({ queryKey: ["getUser", "getInvestor", "getTransactions"] });
  }, [userId, investorId, queryClient]);

  // useSocketIO(userId, (data) => {
  //   if (data.kycStatus) {
  //     const kycMessage =
  //     setKycStatus(data.kycStatus); // Update the kycStatus state dynamically
  //     // toast.success(`KYC status updated to ${data.kycStatus}`);
  //     setErrorModalContent({
  //       title: "Verified",
  //       message: "Congratulations your KYC has been verified successfully. Please whitelist your metamask wallet address to participate in the PNMO presale."
  //     });
  //     setShowErrorModal(true);

  //   } else {
  //     // toast.error(data.message);
  //     // Show error modal instead of toast
  //     setErrorModalContent({
  //       title: "Error",
  //       message: data.message || "An error occurred"
  //     });
  //     setShowErrorModal(true);
  //   }
  // });

  useSocketIO(userId, (data) => {
    if (data.userId === userId) {
      refetchUser();

      if (data.kycStatus) {
        if (data.kycStatus === "DocumentAlreadyUsed") {
          // console.log("Data from IO: ",data)
          setKycStatus(data.kycStatus); // Update the kycStatus state dynamically

          // Check if the user's country is restricted
          const modalMessage = "This document was already used to do KYC.";

          setErrorModalContent({
            title: "Document Already used",
            message: modalMessage,
          });

          setShowErrorModal(true);
        }
        if (data.kycStatus === "Verified") {
          // console.log("Data from IO: ",data)
          setKycStatus(data.kycStatus); // Update the kycStatus state dynamically

          // Check if the user's country is restricted
          const isRestricted = isCountryRestricted();
          const modalMessage = isRestricted ? "Sorry, the PNMO presale is not available for your nationality." : "Congratulations your KYC has been completed.";

          setErrorModalContent({
            title: isRestricted ? "Not Eligible" : "Verified",
            message: modalMessage,
          });

          setShowErrorModal(true);
        }
      } else {
        setErrorModalContent({
          title: "Error",
          message: data.message || "An error occurred",
        });
        setShowErrorModal(true);
      }
    }
  });

  const {
    data: userData = null,
    refetch: refetchUser,
    isLoading: isLoadingUser = false,
  } = useQuery({
    queryKey: ["getUser", userId],
    queryFn: () => fetchUser(userId),
    enabled: !!userId,
  }) || {};

  // const {
  //   data: tokenPurchaseData,
  //   isLoading,
  //   refetch,
  // } = useQuery({
  //   queryKey: ["getTokenPurchaseData", investorId],
  //   queryFn: () => getTokenPurchaseRequests(investorId),
  //   enabled: !!investorId,
  //   select: (res) => res.data,
  // }) || {};

  const { data: investorData = null } =
    useQuery({
      queryKey: ["getInvestor", investorId],
      queryFn: () => fetchInvestor(investorId),
      enabled: !!investorId,
    }) || {};

  useEffect(() => {
    if (userData) {
      setUser(userData.data);
      setKycStatus(userData.data.kycStatus); // Set initial KYC status
    }
    if (investorData && investorData.data) {
      setInvestor(investorData.data);
    }
  }, [userData, investorData, setUser, setInvestor]);

  const getNotifications = () => {
    const notifications = [];

    if (userData?.statusUpdates) {
      const userNotifications = userData.statusUpdates.map((notification: any) => ({
        ...notification,
        type: "user",
      }));
      notifications.push(...userNotifications);
    }

    if (investorData?.statusUpdates) {
      const investorNotifications = investorData.statusUpdates.map((notification: any) => ({
        ...notification,
        type: "investor",
      }));
      notifications.push(...investorNotifications);
    }

    return notifications;
  };

  const notifications = getNotifications();
  const isVerified = kycStatus === "Verified";

  // console.log("KYC: ",userData?.data?.kycStatus)

  const FLIPCARD_FROM_ADDRESS = "0xa7d36B87D814257ADCa79cFe521f2a3fdcDdfDDa".toLowerCase();
  const PENOMO_TOKEN_SYMBOL = "PNMO";

  const getTransactionType = (transaction: any) => {
    const { from, tokenSymbol } = transaction;
    if (tokenSymbol === PENOMO_TOKEN_SYMBOL && from.toLowerCase() === FLIPCARD_FROM_ADDRESS) {
      return "flipCard transaction";
    }
    return transaction.transactionType;
  };

  const getCardClass = (transaction: any) => {
    const transactionType = getTransactionType(transaction);
    return transactionType === "flipCard transaction" ? "cards flipCard-transaction" : "cards";
  };

  // Determine if components should be displayed based on investorType
  const shouldDisplayComponents = () => {
    const investorType = investorData?.data?.investorType;
    return investorType == "Retail";
  };

  // Determine if the user's country of residence is restricted
  const isCountryRestricted = () => {
    const countryOfResidence = userData?.data?.countryOfResidence;
    return restrictedCountries.includes(countryOfResidence);
  };

  return (
    <div className="flex gap-4 flex-col">
      {isLoadingUser ? (
        <div className="min-h-[40px] flex align-center justify-center">
          <LogoLoader isLoading={true} />
        </div>
      ) : (
        !isCountryRestricted() && <KycVerificationBanner isVerified={isVerified} />
      )}
      {userData && (
        <>
          {/* {tokenPurchaseData && tokenPurchaseData.length > 0 && <PurchaseComponent handleModal={handlenModal} />} */}
          {/* {show && <PaymentTokenModal show={show} handleModal={handlenModal} />} */}
          {shouldDisplayComponents() && (
            // <section className="grid grid-cols-1 xl:grid-cols-2 gap-4">
            //   <>
            //     <PenomoPreSale isVerified={isVerified} userData={userData.data} />
            //     <PeaqRewardingCampaign isVerified={isVerified} userData={userData.data} />
            //   </>
            // </section>
            <PenomoPreSale isVerified={isVerified} userData={userData.data} />
          )}
        </>
      )}
      <section className="grid grid-cols-1 xl:grid-cols-2 gap-4">
        <BalanceCard />
        <PenomoFlipCards userData={userData} />
      </section>
      <section className="grid grid-cols-1 xl:grid-cols-2 gap-4 ">
        <RawTokenList />
        <RecentTransactions
          // investorId={investorId} {...props}
          userData={userData?.data}
        />
      </section>

      {/* Error Modal */}
      <Modal show={showErrorModal} onClose={() => setShowErrorModal(false)} position="center" size="xl">
        <Modal.Body className="p-4 bg-monochrome-100">
          <div className="flex justify-between items-center pb-5">
            <h3 className="mb-0">{errorModalContent.title}</h3>
            <Button classNames="w-[32px] bg-monochrome-60 border border-monochrome-40 !px-2 !rounded-xl" onClick={() => setShowErrorModal(false)}>
              <IcCross />
            </Button>
          </div>
          <hr className="border-monochrome-40" />
          <div className="my-6 flex flex-col justify-center items-center text-center space-y-6">
            {errorModalContent.title === "Verified" ? <IcChecked /> : <IcWarning />}
            <p className="text-white text-sm mt-2">{errorModalContent.message}</p>
          </div>
          <div className="flex justify-center mt-8">
            <Button classNames="btn btn-primary w-full" onClick={() => setShowErrorModal(false)}>
              OK
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default DashboardPage;
