//Vijays Work
// import { IcCopy } from "@/assets";
// import Button from "@/components/UI/Button";
// import useAuth from "@/hooks/useAuth";
// import { fetchUser } from "@/services/api";
// import { useQuery } from "@tanstack/react-query";
// import React from "react";
// import toast from "react-hot-toast";

// const Info = ({ setSelectedTab }: any) => {
//   const { userId } = useAuth();
//   const {
//     data: userData,
//     isLoading: isLoadingUser,
//     refetch: refetchUserData,
//   } = useQuery({
//     queryKey: ["getUser", userId],
//     queryFn: () => fetchUser(userId),
//     enabled: !!userId,
//     select: (data) => data.data,
//   });

//   const handleOnCopy = () => {
//     navigator.clipboard.writeText(process.env.REACT_APP_FRONTEND_URL_LOCAL + "?ref=" + userData?.referral_code);
//     toast.success("Referral code copied successfully");
//   };

//   const referralLink = process.env.REACT_APP_FRONTEND_URL_LOCAL + "?ref=" + userData?.referral_code;

//   return (
//     <div className="bg-monochrome-100 p-2 sm:p-4 flex justify-center items-center rounded-lg mx-2 mt-4 sm:mx-4 mb-0 min-h-[calc(100vh-350px)] lg:min-h-[calc(100vh-262px)]">
//       <div className="w-full max-w-3xl flex flex-col items-center px-2 sm:px-4">
//         <p className="text-center text-green-500 text-sm sm:text-base px-2 mb-4">
//           {`Welcome, ${userData?.firstName} ${userData?.lastName}, to the PNMO campaigns! Here, you can earn XP by completing quests. In the future, your XP will be converted into PNMO tokens.

// But that’s not all! You can also earn bonus XP by inviting your friends to join Penomo using your referral link. Start your journey now and maximize your rewards!`}
//         </p>

//         <div className="flex items-center bg-monochrome-40 sm:w-[60%] max-w-md mx-auto rounded-xl border border-green-400">
//           <div className="flex-1 overflow-hidden px-3 py-2">
//             <p className="text-xs sm:text-sm truncate">{referralLink}</p>
//           </div>
//           <button
//             className="cursor-pointer flex-shrink-0 p-2 hover:bg-monochrome-50 rounded-full transition-colors mr-1"
//             onClick={handleOnCopy}
//             aria-label="Copy referral link"
//           >
//             <IcCopy />
//           </button>
//         </div>

//         <div className="flex gap-4">
//           <Button secondary classNames="mt-4 rounded-lg text-black" onClick={() => setSelectedTab(1)}>
//             Go to Quests
//           </Button>
//           <Button secondary classNames="mt-4 rounded-lg text-black" onClick={() => setSelectedTab(2)}>
//             Go to Leaderboard
//           </Button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Info;

import { IcCopy } from "@/assets";
import LogoLoader from "@/components/LogoLoader";
import Button from "@/components/UI/Button";
import useAuth from "@/hooks/useAuth";
import { fetchUser } from "@/services/api";
import { useQuery } from "@tanstack/react-query";
import React from "react";
import toast from "react-hot-toast";

const Info = ({ setSelectedTab }: any) => {
  const { userId } = useAuth();
  const {
    data: userData,
    isLoading: isLoadingUser,
    refetch: refetchUserData,
  } = useQuery({
    queryKey: ["getUser", userId],
    queryFn: () => fetchUser(userId),
    enabled: !!userId,
    select: (data) => data.data,
  });

  const handleOnCopy = () => {
    navigator.clipboard.writeText(process.env.REACT_APP_FRONTEND_URL_LOCAL + "?ref=" + userData?.referral_code);
    toast.success("Referral code copied successfully");
  };

  const referralLink = process.env.REACT_APP_FRONTEND_URL_LOCAL + "?ref=" + userData?.referral_code;

  return (
    <div className="bg-monochrome-100 relative border-b border-l border-r border-[#004C3A] z-0 bg-custom-gradient  flex justify-center items-center mx-2 sm:mx-4 mb-0 min-h-[calc(100vh-350px)] lg:min-h-[calc(100vh-262px)]">
      {isLoadingUser ? (
        <div className="min-h-[40px] flex align-center justify-center">
          <LogoLoader isLoading={true} />
        </div>
      ) : null}
      <div className="bg-custom-gradient">
        <img src="./assets/xrpiconTop.svg" className="flex opacity-[30%] flex-col absolute top-0 right-0" />
        <img src="./assets/xrpiconBottom.svg" className="flex flex-col opacity-[5%] absolute bottom-0 left-0" />
      </div>
      <div className="w-full flex p-2 sm:p-4 flex-col z-10 items-center px-2 sm:px-4">
        <h1 className="text-center md:text-[32px] leading-7 font-bold">
          Earn XP* by referring penomo to <br /> <br /> your friends with the below link.
        </h1>
        <p className="text-center text-green-500 text-xs px-2 mb-4">250 XP will be claimed after your referee has completed a successful KYC on penomo. Another 1000 XP will be claimed after the presale, if your referee has invested in the PNMO presale </p>
        <div className="flex  p-2 border border-[#152B2E] rounded-[32px] w-full  md:w-[60%] lg:w-[40%] shadow-custom ">
          <div className="flex items-center bg-primary-10 w-full  mx-auto rounded-[32px] border border-[#004C3A]">
            <div className="flex-1 overflow-hidden px-3 py-2">
              <p className="text-xs truncate">{referralLink}</p>
            </div>
            <button
              className="cursor-pointer flex-shrink-0 w-[52px] flex justify-center rounded-tl-none rounded-bl-none bg-[#007F61] hover:bg-monochrome-50 rounded-full transition-colors"
              onClick={handleOnCopy}
              aria-label="Copy referral link"
            >
              {/* <IcCopy /> */}

              <img src="./assets/copyIcon.svg" className="flex rounded-r-full p-3" />
            </button>
          </div>
        </div>
        <p className="text-center text-monochrome-20 text-2xs px-2 my-3">*Your XP will be converted into PNMO tokens in the future.</p>
      </div>
    </div>
  );
};

export default Info;
