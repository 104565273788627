// import { IcMetamask, IcTickmark } from '@/assets';
// import IcCross from '@/assets/ic_cross';
// import Loader from '@/components/Loader';
// import Button from '@/components/UI/Button';
// import useAuth from '@/hooks/useAuth';
// import { joinPenomoPreSale } from '@/services/api';
// import { IUser } from '@/types';
// import { Modal } from 'flowbite-react';
// import React, { useState } from 'react'
// import toast from 'react-hot-toast';
// import { z } from 'zod';
// const walletAddressSchema = z.string().regex(/^0x[a-fA-F0-9]{40}$/, "Invalid Wallet Address");

// interface JoinPenomoPreSaleModalProps {
//     handleClose: (isRefresh: boolean) => void;
//     user: IUser;
// }
// enum RenderStatus {
//     NOT_STARTED = "NOT_STARTED",
//     SUCCESS = "SUCCESS",
// }

// const JoinPenomoPreSaleModal = ({ handleClose, user }: JoinPenomoPreSaleModalProps) => {

//     const { userId } = useAuth();

//     const [renderStatus, setRenderStatus] = useState<RenderStatus>(RenderStatus.NOT_STARTED);
//     const [walletAddress, setWalletAddress] = useState("");
//     const [walletAddressError, setWalletAddressError] = useState("");
//     const [isSubmitting, setIsSubmitting] = useState(false);
    
//     const handleWalletAddressChange = (event: any) => {
//         const address = event.target.value;
//         setWalletAddress(address);
        
//         // Validate wallet address format immediately on input
//         const validation = walletAddressSchema.safeParse(address);
//         setWalletAddressError(validation.success ? "" : validation.error.issues[0].message);
//     };

//     const handleSubmit = async () => {
//         try {
//             const validation = walletAddressSchema.safeParse(walletAddress);
//             if (!validation.success) {
//                 setWalletAddressError(validation.error.issues[0].message);
//                 return;
//             }

//             setIsSubmitting(true);
//             const response = await joinPenomoPreSale(userId, {
//                 penomoWalletAddress: walletAddress
//             })
//             setIsSubmitting(false);
//             if(response.code == 201 && response.data) {
//                 setRenderStatus(RenderStatus.SUCCESS);
//             } else {
//                 toast.error(response.message ?? 'Failed to join presale, please try again')
//             }
            
//         } catch (error: any) {
//             toast.error('Failed to join presale, please try again')
//             setIsSubmitting(false);
//         }
//     };

//     return (
//         <Modal show={true} onClose={()=>{handleClose(false)}} position={'center'} size={'2xl'}>
//             <Modal.Body className="p-4 bg-monochrome-100">
//                 <div className={"flex justify-between items-center pb-5"}>
//                     <h2 className="mb-0">Getting Whitelisted</h2>
//                     <Button classNames="w-[32px] bg-monochrome-60 border border-monochrome-40 !px-2 !rounded-xl" rounded
//                         onClick={()=>{handleClose(false)}}>
//                         <IcCross />
//                     </Button>
//                 </div>
//                 <hr className="border-monochrome-40" />
//                 {
//                     renderStatus === RenderStatus.NOT_STARTED && (
//                         <section className="p-4 flex flex-col gap-7 mt-4 items-center">
//                             <IcMetamask />
//                             <div className='flex flex-col text-center gap-2'>
//                                 <h3>Submit your Metamask wallet address</h3>
//                                 <p className='text-sm text-monochrome-20'>Enter your Metamask wallet address to join the PNMO community presale whitelist</p>
//                             </div>
//                             <div className='w-full'>
//                                 <input type='text' className='w-full p-3' onChange={handleWalletAddressChange} placeholder='Enter your MetaMask wallet address' />
//                                 {
//                                     walletAddressError != '' && <p className='text-red-500 mt-2'>{walletAddressError}</p>
//                                 }
//                             </div>
//                             <div className='flex flex-col w-full gap-4'>
//                                 <Button secondary rounded classNames="w-full text-black hover:text-white" onClick={handleSubmit} disabled={isSubmitting || walletAddress == '' || walletAddressError != ''}>
//                                     {isSubmitting ? <Loader isLoading={true} /> : "Join PNMO Community Presale"}
//                                 </Button>
//                             </div>
//                         </section>
//                     )
//                 }
//                 {
//                     renderStatus === RenderStatus.SUCCESS && (
//                         <section className="p-4 flex flex-col gap-7 mt-4 items-center">
//                             <IcTickmark className="h-[50px] p-3 aspect-square text-green-500 bg-green-10 rounded-full" />
//                             <div className='flex flex-col text-center gap-2'>
//                                 <h3>Whitelisting successful</h3>
//                                 <p className='text-sm text-monochrome-20'>You are now eligible to participate in the PNMO presale</p>
//                             </div>
//                             <div className='flex flex-col w-full gap-4 mt-8'>
//                                 <Button primary rounded classNames="w-full" onClick={()=>{handleClose(true)}} >
//                                     Close
//                                 </Button>
//                             </div>
//                         </section>
//                     )
//                 }
//             </Modal.Body>
//         </Modal>
//     )
// }

// export default JoinPenomoPreSaleModal;

import { IcMetamask, IcTickmark, IcWallet } from '@/assets';
import IcCross from '@/assets/ic_cross';
import Loader from '@/components/Loader';
import Button from '@/components/UI/Button';
import useAuth from '@/hooks/useAuth';
import { joinPenomoPreSale } from '@/services/api';
import { IUser } from '@/types';
import { useQueryClient } from '@tanstack/react-query';
import { Modal } from 'flowbite-react';
import React, { useState } from 'react'
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';

const walletAddressSchema = z.string().regex(/^0x[a-fA-F0-9]{40}$/, "Invalid Wallet Address");
const investmentSchema = z.number().positive("Investment amount must be greater than 0");

interface JoinPenomoPreSaleModalProps {
    handleClose: (isRefresh: boolean) => void;
    user: IUser;
}

enum RenderStatus {
    INVESTMENT_AMOUNT = "INVESTMENT_AMOUNT",
    WALLET_ADDRESS = "WALLET_ADDRESS",
    SUCCESS = "SUCCESS",
}

const JoinPenomoPreSaleModal = ({ handleClose, user }: JoinPenomoPreSaleModalProps) => {
    const { userId } = useAuth();
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const [renderStatus, setRenderStatus] = useState<RenderStatus>(RenderStatus.INVESTMENT_AMOUNT);
    const [walletAddress, setWalletAddress] = useState("");
    const [walletAddressError, setWalletAddressError] = useState("");
    const [investmentAmount, setInvestmentAmount] = useState<string>("");
    const [investmentError, setInvestmentError] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    
    // Function to format number with commas
    const formatNumberWithCommas = (value: string): string => {
        // Remove any existing commas and non-numeric characters (except decimal point)
        const cleanValue = value.replace(/,/g, '').replace(/[^\d.]/g, '');
        
        // Split the number into integer and decimal parts
        const [integerPart, decimalPart] = cleanValue.split('.');
        
        // Add commas to the integer part
        const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        
        // Combine integer and decimal parts if decimal exists
        return decimalPart ? `${formattedInteger}.${decimalPart}` : formattedInteger;
    };

    // Function to remove commas for processing
    const removeCommas = (value: string): string => {
        return value.replace(/,/g, '');
    };
    
    const handleInvestmentAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const rawValue = event.target.value;
        const formattedValue = formatNumberWithCommas(rawValue);
        setInvestmentAmount(formattedValue);
        
        // Validate investment amount (remove commas for validation)
        const numericAmount = parseFloat(removeCommas(formattedValue));
        const validation = investmentSchema.safeParse(numericAmount);
        setInvestmentError(validation.success ? "" : validation.error.issues[0].message);
    };

    const handleWalletAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const address = event.target.value;
        setWalletAddress(address);
        
        const validation = walletAddressSchema.safeParse(address);
        setWalletAddressError(validation.success ? "" : validation.error.issues[0].message);
    };

    const handleNavigation = async () => {
        await queryClient.invalidateQueries({ queryKey: ["getUser"] });
        navigate('/presale')
    }

    const handleSubmit = async () => {
        try {
            const addressValidation = walletAddressSchema.safeParse(walletAddress);
            if (!addressValidation.success) {
                setWalletAddressError(addressValidation.error.issues[0].message);
                return;
            }

            setIsSubmitting(true);
            const response = await joinPenomoPreSale(userId, {
                penomoWalletAddress: walletAddress,
                presaleAmountInterestedToInvest: parseFloat(removeCommas(investmentAmount))
            });
            
            setIsSubmitting(false);
            if(response.code == 201 && response.data) {
                setRenderStatus(RenderStatus.SUCCESS);
            } else {
                toast.error(response.message ?? 'Failed to join presale, please try again')
            }
        } catch (error: any) {
            toast.error('Failed to join presale, please try again')
            setIsSubmitting(false);
        }
    };

    const renderInvestmentAmountStep = () => (
        <section className="p-4 flex flex-col gap-7 mt-4 items-center">
            <img src="./assets/ic_penomo_rewards.svg" alt="Penomo Rewards" />
            <div className='flex flex-col text-center gap-2'>
                <h3>Investment Amount</h3>
                <p className='text-sm text-monochrome-20'>
                Beyond the community sale capped at $150, you may be eligible for the private sale. Please indicate your total investment wish in USD (e.g. $10,000).
                </p>
            </div>
            <div className='w-full'>
                <div className='relative'>
                    <input 
                        type='text' 
                        className='w-full p-3 pr-24 appearance-none'
                        onChange={handleInvestmentAmountChange} 
                        placeholder='Enter investment amount in USDT' 
                        value={investmentAmount}
                    />
                    <span className='absolute right-3 top-1/2 transform -translate-y-1/2 text-monochrome-20'>
                        USD
                    </span>
                </div>
                {investmentError && <p className='text-red-500 mt-2'>{investmentError}</p>}
            </div>
            <div className='flex flex-col w-full gap-4'>
                <Button 
                    secondary 
                    rounded 
                    classNames="w-full text-black" 
                    onClick={() => setRenderStatus(RenderStatus.WALLET_ADDRESS)}
                    disabled={!investmentAmount || !!investmentError}
                >
                    Next
                </Button>
            </div>
        </section>
    );

    const renderWalletAddressStep = () => (
        <section className="p-4 flex flex-col gap-7 mt-4 items-center">
            <IcMetamask />
            {/* <IcWallet /> */}
            <div className='flex flex-col text-center gap-2'>
                <h3>Submit your external wallet address</h3>
                <p className='text-sm text-monochrome-20'>
                    Enter your external wallet address to join the PNMO community presale whitelist. The token sale is done with  <a href="https://www.bitbond.com/" target='_blank' rel="noreferrer" className='font-bold underline'>Bitbond</a> , which supports all the major EVM wallet providers (Metamask, Trustwallet, ...).
                </p>
            </div>
            <div className='w-full'>
                <input 
                    type='text' 
                    className='w-full p-3' 
                    onChange={handleWalletAddressChange} 
                    placeholder='Enter your external wallet address' 
                    value={walletAddress}
                />
                {walletAddressError && <p className='text-red-500 mt-2'>{walletAddressError}</p>}
            </div>
            <div className='flex flex-col w-full gap-4'>
                <div className='flex gap-4'>
                    <Button 
                        primary 
                        rounded 
                        classNames="w-1/2 text-black text-xs" 
                        onClick={() => setRenderStatus(RenderStatus.INVESTMENT_AMOUNT)}
                    >
                        Back
                    </Button>
                    <Button 
                        secondary 
                        rounded 
                        classNames="w-1/2 text-black" 
                        onClick={handleSubmit} 
                        disabled={isSubmitting || walletAddress === '' || walletAddressError !== ''}
                    >
                        {isSubmitting ? <Loader isLoading={true} /> : "Join PNMO Community Presale"}
                    </Button>
                </div>
            </div>
        </section>
    );

    const renderSuccessStep = () => (
        <section className="p-4 flex flex-col gap-7 mt-4 items-center">
            <IcTickmark className="h-[50px] p-3 aspect-square text-green-500 bg-green-10 rounded-full" />
            <div className='flex flex-col text-center gap-2'>
                <h3>Whitelisting Initiated</h3>
                <p className='text-sm text-monochrome-20'>
                    We have initiated your whitelisting process. After completion, the presale page access will be activated for your account and your whitelisted wallet. Please revisit later in the next hours (can take up to 24 hours).
                </p>
            </div>
            <div className='flex flex-col w-full gap-4 mt-8'>
                <Button primary rounded classNames="w-full text-xs" onClick={() => handleNavigation()}>
                    PNMO presale page
                </Button>
            </div>
        </section>
    );

    return (
        <Modal show={true} onClose={() => handleClose(false)} position={'center'} size={'2xl'}>
            <Modal.Body className="p-4 bg-monochrome-100">
                <div className={"flex justify-between items-center pb-5"}>
                    <h2 className="mb-0">Getting Whitelisted</h2>
                    {/* <Button 
                        classNames="w-[32px] bg-monochrome-60 border border-monochrome-40 !px-2 !rounded-xl" 
                        rounded
                        onClick={() => handleClose(false)}
                    >
                        <IcCross />
                    </Button> */}
                </div>
                <hr className="border-monochrome-40" />
                
                {renderStatus === RenderStatus.INVESTMENT_AMOUNT && renderInvestmentAmountStep()}
                {renderStatus === RenderStatus.WALLET_ADDRESS && renderWalletAddressStep()}
                {renderStatus === RenderStatus.SUCCESS && renderSuccessStep()}
            </Modal.Body>
        </Modal>
    )
}

export default JoinPenomoPreSaleModal;
