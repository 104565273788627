import React from "react";
import Select from "react-select";
import { DropdownProps, Option } from "./DropdownProps";
import { IcDropdownChecked } from "@/assets/menu";
import { components } from "react-select";
import IcSeparator from "@/assets/ic_separator";
import IcAcArrowDown from "@/assets/menu/ic_ac_arrow_down";
const { Control } = components;

const Dropdown: React.FC<DropdownProps> = ({
  options,
  onChange,
  placeholder,
  icon,
  value,
  disabled,
  className,
  height,
  menuBoxMarginTop = 25,
  isSearchable = false,
}) => {
  const handleChange = (selectedOption: Option | null) => {
    onChange(selectedOption);
  };

  return (
    <Select
      options={options}
      className={className}
      placeholder={placeholder}
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          border: "none",
          boxShadow: "none",
          borderColor: "transparent",
          height: height,
          color: "#ffffff",
          fontSize: "11px",
          background: "#383838",
          borderRadius: "6px",
          padding: "10px",
        }),
        menu: (provided) => ({
          ...provided,
          marginTop: menuBoxMarginTop + "px",
          backgroundColor: "#1A1C22",
          borderRadius: "10px",
          borderColor: "transparent",
          color: "black",
          padding: "0",
        }),
        singleValue: (provided) => ({
          ...provided,
          color: "#ffffff",
          fontSize: "11px",
        }),
        indicatorsContainer: (provided) => ({
          ...provided,
          color: "#ffffff",
          padding: "0px",
        }),
      }}
      value={options?.find((option) => option.value === value)}
      components={{
        IndicatorSeparator: () => <IcSeparator />,
        IndicatorsContainer: () => (
          <div className="">
            <IcAcArrowDown />
          </div>
        ),
        Control: ({ children, ...props }: any) => {
          const selected = props.getValue()[0];
          return (
            <Control className="flex items-center gap-2 h-full w-full text-sm select-dropdown" {...props}>
              {icon}
              {children}
            </Control>
          );
        },
        Option: ({ innerProps, label, data, isSelected }: any) => {
          return (
            <div
              className={`flex items-center justify-between gap-2 h-10 px-2 text-white cursor-pointer first:border-none border-t border-[#383838] ${
                isSelected ? "bg-[#383838]" : "hover:bg-[#383838]"
              }`}
              {...innerProps}
            >
              <div className="flex items-center gap-2">
                {data.icon &&
                  // <img className="w-4 aspect-square" src={data.icon}></img>
                  data.icon}
                <span className={`text-sm`}>{label}</span>
              </div>
              {isSelected && <IcDropdownChecked />}
            </div>
          );
        },
      }}
      isSearchable={isSearchable}
      onChange={(val) => {
        handleChange(val);
      }}
      isDisabled={disabled}
    />
  );
};

export default Dropdown;
