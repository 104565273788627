import { ROUTES } from "@/constants";
import React from "react";
import { useLocation } from "react-router-dom";

interface FooterProps {
  onPrivacyPolicyClick: () => void;
}

const Footer: React.FC<FooterProps> = ({ onPrivacyPolicyClick }: FooterProps) => {
  const { pathname } = useLocation();

  return (
    <>
      <footer
        className={`z-50 w-full flex-col sm:flex-row flex justify-between px-8 rounded-[8px] ${
          pathname == ROUTES.APP_ROOT || pathname == ROUTES.PROFILE_SETUP
            ? "!justify-center"
            : "w-[calc(100%-25px)] xl:w-[calc(100%-30px)] justify-between mx-auto"
        } bg-monochrome-100 text-white text-center text-xs py-5 `}
      >
        <p>© 2025 penomo Foundation Ltd.</p>
        {pathname == ROUTES.APP_ROOT || pathname == ROUTES.PROFILE_SETUP ? null : (
          <p onClick={onPrivacyPolicyClick} className="cursor-pointer underline mt-2 sm:mt-0">
            Privacy Policy
          </p>
        )}
      </footer>
    </>
    // fixed bottom-0 right-0 sm:right-3 lg:right-2 xl:right-4
  );
};

export default Footer;
