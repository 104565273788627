import React from 'react';

const agreementData = {
  title: "Effective Date: 01.10.2024",
  sections: [
    {
      title: "1. Introduction",
      content:
        "Welcome to invest.penomo.com. This Privacy Policy explains how we, Penomo LTD., a company registered in British Virgin Islands, collect, use, store, and protect your personal information when you visit or use our website invest.penomo.com. We are committed to safeguarding your privacy and ensuring your data is handled responsibly.",
    },
    {
      title: "2. Information We Collect",
      content: [
        "We collect your personal information to provide and improve our services. The types of information we may collect include:",
        "- Name",
        "- Email address",
        "- Passport ID",
        "- National ID",
        "- Nationality",
        "- Social media profiles",
      ],
    },
    {
      title: "3. How We Use Your Information",
      content: [
        "We use your personal information for various purposes, including:",
        "- Processing payments",
        "- Fulfillment of orders and delivery",
        "- Contacting users",
        "- Granting access to our website",
        "- Record keeping and administration",
        "- Running competitions, sweepstakes, and/or giveaways",
        "- Compliance with legal obligations and dispute resolution",
        "- Content attribution",
        "- Ensuring security and fraud prevention",
        "- Developing and improving products and services",
        "- User account registration",
        "- Sending email newsletters",
      ],
    },
    {
      title: "4. Third-Party Services",
      content:
        "We use third-party services to help us operate our website and provide our services. One such service provider is AWS SES for email services. We ensure that all third-party services comply with applicable data protection laws and have appropriate safeguards in place to protect your data.",
    },
    {
      title: "5. Data Storage and Processing",
      content:
        "Your personal information is stored and processed in Germany. We ensure that your data is protected in accordance with the General Data Protection Regulation (GDPR).",
    },
    {
      title: "6. Data Protection Rights",
      content: [
        "Under the GDPR, you have the following rights concerning your personal data:",
        "- The right to access your data",
        "- The right to rectify inaccurate data",
        "- The right to erasure (deletion) of your data",
        "- The right to restrict processing",
        "- The right to data portability",
        "- The right to object to processing",
        "If you wish to exercise any of these rights or have any questions about your data, please contact us at hello@penomo.io.",
      ],
    },
    {
      title: "7. Data Deletion Requests",
      content:
        "If you request the deletion of your personal data, we commit to deleting it within 30 days from the date of your request.",
    },
    {
      title: "8. Contact Us",
      content: [
        "For any questions, concerns, or requests regarding this Privacy Policy or your personal data, please contact us via hello@penomo.io",
      ],
    },
    {
      title: "9. Changes to This Privacy Policy",
      content:
        "We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated effective date. We encourage you to review this Privacy Policy periodically to stay informed about how we are protecting your information.",
    },
    {
      title: "10. Compliance with GDPR",
      content:
        "As a Data Processor, we process personal information as per instructions from a Data Controller. We are committed to complying with all relevant data protection laws and regulations.",
    },
  ],
};



const PrivacyPolicy = () => {
  const renderContent = (content, sectionIndex) => {
    const result = [];
    let currentSubItems = [];

    content.forEach((item, idx) => {
      if (typeof item === "string" && item.startsWith("-")) {
        // If it is a sub-item, add it to the currentSubItems array
        currentSubItems.push(item.slice(2)); // Remove the "- " prefix
      } else {
        // If it's a main item, process any accumulated sub-items
        if (currentSubItems.length > 0) {
          result.push(
            <ul 
              className="list-disc pl-8 text-monochrome-20" 
              key={`section-${sectionIndex}-sub-list-${idx}`}
            >
              {currentSubItems.map((subItem, subIdx) => (
                <li key={`section-${sectionIndex}-sub-item-${idx}-${subIdx}`}>
                  {subItem}
                </li>
              ))}
            </ul>
          );
          currentSubItems = []; // Reset sub-items
        }
        result.push(
          <li key={`section-${sectionIndex}-main-${idx}`} className="list-item">
            {item}
          </li>
        );
      }
    });

    // Process remaining sub-items after the loop
    if (currentSubItems.length > 0) {
      result.push(
        <ul 
          className="list-disc pl-8 text-monochrome-20"
          key={`section-${sectionIndex}-final-sub-list`}
        >
          {currentSubItems.map((subItem, subIdx) => (
            <li key={`section-${sectionIndex}-final-sub-item-${subIdx}`}>
              {subItem}
            </li>
          ))}
        </ul>
      );
    }

    return result;
  };

  return (
    <div className="w-[100%] mx-auto p-3 min-h-[301px] max-h-[301px] rounded-md border border-monochrome-20 bg-monochrome-150 overflow-y-scroll">
      <p className="mb-4 font-semibold">{agreementData.title}</p>
      {agreementData.sections.map((section, index) => (
        <div key={`section-${index}`} className="mb-6">
          <p className="font-bold mb-2">{section.title}</p>
          {Array.isArray(section.content) ? (
            <ul className="list-disc pl-6 text-monochrome-20">
              {renderContent(section.content, index)}
            </ul>
          ) : (
            <p className="text-monochrome-20">{section.content}</p>
          )}
        </div>
      ))}
    </div>
  );
};

export default PrivacyPolicy;