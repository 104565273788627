import { jwtDecode } from "jwt-decode";
import { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Web3 from "web3";

import { AuthContext } from "@/provider/AuthContext";
import { ENDPOINT, ROUTES } from "@constants";
import { useWeb3Auth } from "@services/Web3AuthService";
import { fetchUser } from "@services/api";
import { http } from "@services/http";
import useStore from "@store/useStore";
import { WALLET_ADAPTERS } from "@web3auth/base";
import Cookies from "js-cookie";
import { z } from "zod";
import ExistingEmailModal from "./ExistingEmailModal";
import Loader from "./Loader";
import LogoLoader from "./LogoLoader";
import Button from "./UI/Button";

interface Web3AuthResponse {
  authToken: string;
  investorId: string;
  userId: string;
}

const emailSchema = z.string().email("Please enter a valid email address");

const Login = () => {
  const [isInitializing, setIsInitializing] = useState<boolean>(false);
  const [initialized, setInitialized] = useState<boolean>(false);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [emailError, setEmailError] = useState<string | null>(null);
  const [showExistingEmailModal, setShowExistingEmailModal] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();
  const { setUser } = useStore();
  const { web3auth, initializeWeb3Auth } = useWeb3Auth();
  const [redirecting, setRedirecting] = useState<boolean>(false);
  const [logoLoader, setLogoLoader] = useState(false);

  useEffect(() => {
    const authToken = localStorage.getItem("authToken");
    const web3AuthToken = localStorage.getItem("web3AuthToken");
    if (authToken && web3AuthToken) {
      setIsLoggedIn(true);
    }

    const storedEmail = Cookies.get("email");
    if (storedEmail) {
      setEmail(storedEmail);
    }
  }, []);

  const handleWeb3AuthLogin = async (email: string) => {
    if (isInitializing) return;
    setIsInitializing(true);

    try {
      const validationResult = emailSchema.safeParse(email);

      if (!validationResult.success) {
        setEmailError(validationResult.error.issues[0].message);
        setIsInitializing(false);
        return;
      }
      setEmailError(null);

      // Check if email is whitelisted

      // if (!isEmailWhitelisted(email)) {
      // setErrorMessage("Currently only a closed group of users are allowed. \n\nPublic release coming soon.");
      // setShowExistingEmailModal(true);
      // setIsInitializing(false);
      // return;
      // }

      if (!initialized) {
        await initializeWeb3Auth();
        setInitialized(true);
      }

      if (!web3auth.connected) {
        await web3auth.connectTo(WALLET_ADAPTERS.AUTH, {
          loginProvider: "email_passwordless",
          extraLoginOptions: {
            login_hint: email,
          },
        });
      }

      setLogoLoader(true);
      const userInfo = await web3auth.getUserInfo();
      const emailFromUserInfo = userInfo.email;

      if (!web3auth.provider) {
        toast.error("Web3Auth provider is not available! Try again.");
        throw new Error("Web3Auth provider is not available");
      }

      const web3 = new Web3(web3auth.provider as any);
      const accounts = await web3.eth.getAccounts();
      if (accounts.length === 0) throw new Error("No account found");
      const publicAddress = accounts[0];

      const idToken = (await web3auth.authenticateUser()).idToken;

      try {
        const response = await http.post<Web3AuthResponse>(
          `${ENDPOINT.INVESTOR}/me/web3auth`,
          { email: emailFromUserInfo, publicAddress },
          {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          }
        );

        if (response?.status === 401) {
          // setErrorMessage("Currently only a closed group of users are allowed. \n\nPublic release coming soon.");
          setErrorMessage("Please whitelist your email on https://www.penomo.io \n\nPublic release coming soon.");
          setShowExistingEmailModal(true);
        } else if (response?.status === 409) {
          setErrorMessage(response.data?.message);
          setShowExistingEmailModal(true);
          setLogoLoader(false);
        } else if (response?.status === 200) {
          const authToken = response.data?.data?.authToken;
          if (authToken) {
            login(authToken, idToken, web3auth.provider);
            localStorage.setItem("authToken", authToken);
            localStorage.setItem("web3AuthToken", idToken);
            Cookies.set("email", emailFromUserInfo, { expires: 365 });

            const decoded: any = jwtDecode(authToken);
            const userId = decoded?.userId;
            const userData = await fetchUser(userId);
            if (userData.data) {
              setUser(userData.data);
              const isProfileExisting = userData.data.isProfileExisting;

              setRedirecting(true);
              setLogoLoader(false);

              if (!isProfileExisting) {
                navigate(ROUTES.PROFILE_SETUP);
              } else {
                navigate(ROUTES.DASHBOARD);
              }
            }
          }
        }
      } catch (error: any) {
        if (error.response) {
          setErrorMessage(error.response.data.message);
          setShowExistingEmailModal(true);
        } else {
          toast.error("Login failed. Please try again.");
        }
        console.error("Web3Auth login error:", error);
      }
    } catch (error) {
      console.error("Web3Auth login error:", error);
    } finally {
      setIsInitializing(false);
      setLogoLoader(false);
    }
  };

  const handleEnterKey = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleWeb3AuthLogin(email);
    }
  };

  if (redirecting) {
    return (
      <div className="mt-2">
        <Loader isLoading={redirecting} />
      </div>
    );
  }

  return (
    <>
      {!isLoggedIn && (
        <div className="mt-8 flex-col flex items-center justify-between md:w-[400px] sm:min-w-[400px] min-w-full">
          <div className="flex items-center justify-between w-full">
            <input
              type="email"
              placeholder="Enter your email address"
              value={email}
              className="text-xs w-full !bg-monochrome-60 border !rounded-lg p-2 focus:ring-2 focus:ring-green-500 focus:border-green-500 outline-none"
              onChange={(e) => {
                setEmail(e.target.value);
                setEmailError(null);
              }}
              onKeyDown={handleEnterKey}
            />
            <Button
              classNames="text-black text-xs !py-1 !px-4 ml-[8px] min-w-[82px] !min-h-[42px]"
              onClick={() => handleWeb3AuthLogin(email)}
              secondary
              rounded
            >
              Log in
            </Button>
          </div>
          {emailError && <p className="text-red-500 text-xs mt-2">{emailError}</p>}
        </div>
      )}

      {logoLoader && <LogoLoader isLoading={true} />}

      <ExistingEmailModal
        show={showExistingEmailModal}
        message={errorMessage}
        handleClose={async () => {
          Cookies.remove("email");
          localStorage.clear();
          if (web3auth.connected) {
            // Check if the user is connected before logging out
            await web3auth.logout();
          }
          setShowExistingEmailModal(false);
          // window.location.reload();
        }}
      />
    </>
  );
};

export default Login;
