import React from "react";

interface CustomCheckboxProps {
  checked: boolean;
  onChange: () => void;
  label: string;
}

const CustomCheckbox: React.FC<CustomCheckboxProps> = ({ checked, onChange, label }) => {
  return (
    <div className="flex items-center gap-2 cursor-pointer" onClick={onChange}>
      <div className={`w-4 h-4 flex items-center justify-center border-2 border-monochrome-40 ${checked ? "bg-green-500" : "bg-monochrome-60"}`}>
        {checked && (
          <svg className="text-white" fill="none" stroke="#1A1C22" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
          </svg>
        )}
      </div>
      <label className="select-none w-full">{label}</label>
    </div>
  );
};

export default CustomCheckbox;
