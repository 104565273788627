let API_BASE_URL = process.env.REACT_APP_PENOMO_BACKEND_API;

export const ENDPOINT = {
  USER: `${API_BASE_URL}/api/users`,
  INVESTOR: `${API_BASE_URL}/api/investors`,
  NOTIFICATION: `${API_BASE_URL}/api/investors/statusMessages/me`,
  READ_NOTIFICATION: `${API_BASE_URL}/api/users/notifications/markRead`,
  READ_ALL_NOTIFICATION: `${API_BASE_URL}/api/investors/notifications/real-all`,
  CHALLENGES: `${API_BASE_URL}/api/challenges`,

  // TODO: NOT BEING CALLED IN THE RIGHT WAY INSIDE LOGIN.TSX
  WEB3_AUTH: `${API_BASE_URL}/api/investors/web3auth`,

  // TRANSACTIONS: `${API_BASE_URL}/api/transactions/investor`,
  // KYC_SUBMIT: `${API_BASE_URL}/api/user/submit/kyc`,
  // KYB_SUBMIT: `${API_BASE_URL}api/investor/kyb/submit`,
  // CONTACT_SUPPORT: `${API_BASE_URL}/api/users/contactSupport`,
  // PROJECTS: `${API_BASE_URL}/api/projects/all`,
  // PROJECTSBYID: `${API_BASE_URL}/api/projects`,
  // DOCS: `${API_BASE_URL}/api/documents`,
  // TOKEN_TRANSFERS: `${API_BASE_URL}/api/tokenTransfers`,
  GET_TOKEN_HOLDINGS: `${API_BASE_URL}/api/transactions/getAllRwaTokens`,
  TWITTER: `${API_BASE_URL}/api/twitter/`,
  DISCORD: `${API_BASE_URL}/api/discord/`,
  TELEGRAM: `${API_BASE_URL}/api/telegram/`,
  FLIPCARDS: `${API_BASE_URL}/api/flipCards`,
  // SEND_TRANSACTION:`${API_BASE_URL}/api/transactions/createTWithdrawTransactions`,
} as const;

export const ROUTES = {
  APP_ROOT: "/",
  DASHBOARD: "/dashboard",
  MARKETPLACE: "/marketplace",
  MARKETPLACEDETAILS: "/marketplace/details",
  TRANSACTIONS: "/transactions",
  STAKING: "/staking",
  NOTIFICATIONS: "/notifications",
  CONTACTSUPPORT: "/contact-support",
  CAMPAIGN: "/campaigns",
  PORTFOLIO: "/portfolio",
  GOVERNANCE: "/governance",
  TOKEN_SALE: "/tokensale",
  TOKEN_PURCHASE: "/token-purchase",
  PNMO_PRESALE: "/presale",
  SETTINGS: "/settings",
  SUPPORT: "/support",
  LOGOUT: "/logout",
  PROFILE_SETUP: "/setupProfile",
} as const;

export const getNetworkImageSrc = (selectedNetwork: string): string => {
  switch (selectedNetwork) {
    case "peaq":
      return "./assets/ic_peaq.svg";
    case "PEAQ":
      return "./assets/ic_peaq.svg";
    case "polygon":
      return "https://cryptologos.cc/logos/usd-coin-usdc-logo.png?v=035";
    case "POL":
      return "./assets/ic_polygon.svg";
    case "USDC":
      return "https://cryptologos.cc/logos/usd-coin-usdc-logo.png?v=035";
    case "ethereum":
      return "./assets/ic_ethereum.svg";
    default:
      return "./assets/default_icon.svg"; // Fallback for unknown networks
  }
};
export const validateEthereumAddress = (address: string) => {
  // Ethereum address regex
  const regex = /^0x[a-fA-F0-9]{40}$/;
  return regex.test(address);
};
