import Footer from "@components/Footer";
import Login from "@components/Login";
import logo from "../assets/penomo_logo_white.svg";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

const HomePage = () => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  /**
   * Checks if the user is already logged in by checking the existence of authToken and web3AuthToken in localStorage.
   * If both tokens exist, it sets the isLoggedIn state to true.
   */
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const refCode = searchParams.get("ref") || "";

  useEffect(() => {
    if (refCode) {
      sessionStorage.setItem("refCode", refCode);
    }
  }, [refCode]);

  useEffect(() => {
    // Check local storage for existing tokens
    const authToken = localStorage.getItem("authToken");
    const web3AuthToken = localStorage.getItem("web3AuthToken");
    if (authToken && web3AuthToken) {
      setIsLoggedIn(true);
      navigate("/dashboard");
    }
  }, [navigate]);

  return (
    <div className="flex home-main gap-28 mb-28 h-full">
      {!isLoggedIn && (
        <div className="w-2/4 hidden xl:flex flex-col h-full gap-4">
          <div className="flex w-full h-[32%] gap-4">
            <img className="w-1/3 rounded overflow-hidden" loading="lazy" alt="solar" src={"./assets/dashboard/soloar.png"} />
            <img className="w-2/3 rounded overflow-hidden" loading="lazy" alt="green energy" src={"./assets/dashboard/green_3.png"} />
          </div>
          <div className="flex w-full h-[32%] gap-4">
            <img className="w-1/3 rounded overflow-hidden" loading="lazy" alt="green energy" src={"./assets/dashboard/green_1.png"} />
            <img className="w-2/3 rounded overflow-hidden" loading="lazy" alt="wind energy" src={"./assets/dashboard/wind.png"} />
          </div>
          <div className="flex w-full h-[32%] gap-4">
            <img className="w-1/3 rounded overflow-hidden" loading="lazy" alt="ev charging station" src={"./assets/dashboard/chargin_station.png"} />
            <img className="w-1/3 rounded overflow-hidden" loading="lazy" alt="green energy" src={"./assets/dashboard/green_2.png"} />
            <img className="w-1/3 rounded overflow-hidden" loading="lazy" alt="ev charging point" src={"./assets/dashboard/ev_charging_point.png"} />
          </div>
        </div>
      )}

      <div className={`w-full ${!isLoggedIn ? "xl:w-2/4" : ""} flex flex-col items-center justify-center`}>
        <img src={logo} className=" md:w-67 h-14 mb-4" alt="penomo logo" />
        <p className="sm:w-[400px] text-[1rem] text-center leading-5 sm:leading-3">Own physical infrastructure that powers the world</p>
        <Login />
        {!isLoggedIn && (
          <p className="mt-6" style={{ fontSize: "12px", color: "#8E8E8E" }}>
            Haven&apos;t registered? Log in to create an account
          </p>
        )}
      </div>
    </div>
  );
};

export default HomePage;
