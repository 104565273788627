import { useEffect, useState } from "react";
import { IUser } from "@/types";
import { IcArrowNext, IcArrowUp, IcJoinPenomoPreSale, IcPenomoWhitelistJoined } from "@/assets";
import Button from "./UI/Button";
import JoinPenomoPreSaleModal from "./Page/Dashboard/Dialogs/ModalJoinPenomoPreSale";
import PenomoPreSaleCounter from "./Page/Dashboard/PenomoPreSaleCounter";

// // Define a list of restricted countries by their short codes
// const restrictedCountries = ["IR", "KP", "SY", "US", "UM","IRN","PRK","SYR","USA",
//   // "IN","IND"
// ];

// Define a list of restricted countries by their short codes
const restrictedCountries = [
  "US",
  "USA", // United States
  "CN",
  "CHN", // China
  "KP",
  "PRK", // North Korea
  "IR",
  "IRN", // Iran
  "AF",
  "AFG", // Afghanistan
  "SY",
  "SYR", // Syria
  "CU",
  "CUB", // Cuba
  "DZ",
  "DZA", // Algeria
  "EG",
  "EGY", // Egypt
  "MA",
  "MAR", // Morocco
];

interface PenomoPreSaleProps {
  isVerified: boolean;
  userData: IUser;
}

const PenomoPreSale = ({ isVerified, userData }: PenomoPreSaleProps) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isWhitelisted, setIsWhitelisted] = useState<boolean>(false);
  const [isWhitelistedBitbond, setIsWhitelistedBitbond] = useState<boolean>(false);
  const [timerSecound, setTimerSecound] = useState<number>(0);
  const [isSaleEnded, setIsSaleEnded] = useState<boolean>(false);

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = (isRefresh: boolean = false) => {
    setIsWhitelisted(isRefresh);
    setShowModal(false);
  };

  useEffect(() => {
    if (userData && userData.walletDetails) {
      setIsWhitelisted(userData.walletDetails.isWalletWhitelisted);
    }
  }, [userData]);

  useEffect(() => {
    if (userData && userData.walletDetails) {
      setIsWhitelistedBitbond(userData.walletDetails.isWalletWhitelistedBitbond);
    }
  }, [userData]);

  // Check if the user's country of residence is restricted
  const isCountryRestricted = userData && restrictedCountries.includes(userData.countryOfResidence);

  if (!isVerified) {
    return (
      <section className="w-full">
        <div className="bg-monochrome-100 px-3 py-3 flex rounded-lg">
          <div className="flex grow items-center">
            <IcJoinPenomoPreSale className="h-[40px] aspect-square bg-monochrome-60 rounded-[8px] text-monochrome-20" />
            <div className="flex flex-col ml-4 text-monochrome-20">
              <h4 className="font-medium text-monochrome-20 text-sm">Whitelist your wallet to join the PNMO presale</h4>
              <p className="mt-2 font-normal text-monochrome-20">
                {isCountryRestricted ? "PNMO presale is not allowed for your nationality." : "Please complete your KYC first."}
              </p>
            </div>
          </div>
          <Button disabled transparent={true}>
            <IcArrowNext />
          </Button>
        </div>
      </section>
    );
  }

  if (timerSecound > 0) {
    return (
      <section className="w-full">
        <div className={`flex-col sm:flex-row gap-4 sm:gap-0 bg-monochrome-100 px-4 py-4 flex rounded-lg items-center`}>
          <div className="grid sm:grid-cols-3 gap-4 sm:gap-0 items-center text-monochrome-20 w-full">
            <div className="flex items-center gap-4">
              <IcJoinPenomoPreSale className="h-[40px] aspect-square bg-monochrome-60 rounded-[8px] text-green-500" />
              <h3 className="font-medium text-white text-sm">Presale starts in</h3>
            </div>
            <PenomoPreSaleCounter seconds={timerSecound} />
          </div>
        </div>
      </section>
    );
  }

  if (isSaleEnded) {
    return (
      <section className="w-full">
        <div className={`flex flex-col sm:flex-row gap-4 sm:gap-0 bg-monochrome-100 px-4 py-4 rounded-lg items-center`}>
          <div className="flex items-center w-full">
            <IcJoinPenomoPreSale className="h-[40px] aspect-square bg-monochrome-60 rounded-[8px] text-green-500" />
            <div className="flex ml-4 text-monochrome-20 w-full items-center justify-between">
              <h3 className="font-medium text-white text-sm">Get PNMO</h3>
              <Button rounded primary>
                Purchase PNMO <IcArrowUp />
              </Button>
            </div>
          </div>
        </div>
      </section>
    );
  }

  if (isWhitelisted && !isWhitelistedBitbond) {
    return (
      <section className="w-full">
        <div className={`flex-col sm:flex-row gap-4 sm:gap-0 bg-monochrome-100 px-4 py-4 flex rounded-lg items-center`}>
          <div className="flex grow items-center">
            <IcJoinPenomoPreSale className="h-[40px] aspect-square bg-monochrome-60 rounded-[8px] text-green-500" />
            <div className="flex flex-col ml-4 text-monochrome-20">
              <h4 className="font-normal text-sm flex gap-x-2 items-center text-white">
                We are whitelisting your wallet for the PNMO community presale. <IcPenomoWhitelistJoined className="text-green-500" />
              </h4>
              <div className="font-medium mt-2">We will notify you once done.</div>
            </div>
          </div>
        </div>
      </section>
    );
  }

  if (isWhitelisted && isWhitelistedBitbond) {
    return null; // Render nothing
  }

  if (isCountryRestricted) {
    return (
      <section className="w-full">
        <div className={`flex-col sm:flex-row gap-4 sm:gap-0 bg-monochrome-100 px-4 py-4 flex rounded-lg items-center`}>
          <div className="flex grow items-center">
            <IcJoinPenomoPreSale className="h-[40px] aspect-square bg-monochrome-60 rounded-[8px] text-red-500" />
            <div className="flex flex-col ml-4 text-monochrome-20">
              <h3 className="font-medium text-sm text-white">PNMO presale is not allowed for your nationality.</h3>
            </div>
          </div>
          <Button disabled transparent={true}>
            <IcArrowNext />
          </Button>
        </div>
      </section>
    );
  }

  return (
    <section className="w-full">
      <div className={`flex-row gap-4 sm:gap-0 bg-[#00cc9c] px-4 py-4 flex rounded-lg items-center`}>
        <div className="flex grow items-center">
          <IcJoinPenomoPreSale className="h-[40px] aspect-square bg-monochrome-60 rounded-[8px] text-green-500" />
          <div className="flex flex-col ml-4 text-monochrome-20">
            <h3 className="font-medium text-white">Whitelist your wallet to join the PNMO presale</h3>
          </div>
        </div>
        <Button transparent={true} onClick={handleOpenModal}>
          <IcArrowNext />
        </Button>
      </div>
      {userData && showModal && <JoinPenomoPreSaleModal handleClose={handleCloseModal} user={userData} />}
    </section>
  );
};

export default PenomoPreSale;
