import React from "react";
import Button from "./UI/Button";
import IcCross from "@/assets/ic_cross";
import { Modal } from "flowbite-react";
import PrivacyPolicy from "@/pages/PrivacyPolicy";

interface PrivacyPolicyModalProps {
  show: boolean;
  onClose: () => void;
}

const PrivacyPolicyModal: React.FC<PrivacyPolicyModalProps> = ({ show, onClose }: PrivacyPolicyModalProps) => {
  return (
    <Modal show={show} onClose={onClose} position={"center"} size={"2xl"}>
      <Modal.Body className="p-4 bg-monochrome-100">
        <div className={"flex justify-between items-center pb-4"}>
          <h2 className="mb-0 font-semibold leading-8">Privacy Policy</h2>
          <Button classNames="w-[20px] h-[20px] bg-monochrome-60 border border-monochrome-40 !px-2 !rounded-lg" rounded onClick={onClose}>
            <IcCross svgProps={{ className: "w-[12px] h-[12px]" }} />
          </Button>
        </div>
        <hr className="border-monochrome-40" />
        <section className="flex flex-col lg:flex-row rounded divide-y lg:divide-y-0 divide-x-0 lg:divide-x divide-monochrome-10 pt-4">
          <div className="flex flex-col body-small-regular gap-4 !pt-0 mobile-space  w-full">
            <PrivacyPolicy />
            <Button type="submit" classNames="w-full text-xs text-black hover:text-white" primary rounded onClick={onClose}>
              Close
            </Button>
          </div>
        </section>
      </Modal.Body>
    </Modal>
  );
};

export default PrivacyPolicyModal;
