import React from "react";

const IcSeparator = () => {
  return (
    <svg width="2" height="17" viewBox="0 0 2 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path id="Vector 16" d="M1 0.5V16.5" stroke="#9E9E9E" strokeWidth="0.5" />
    </svg>
  );
};

export default IcSeparator;
