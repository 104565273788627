const IcCross = ({ svgProps, ...props }: any) => {
  return (
    <div {...props}>
      <svg width="16" height="16" viewBox="0 0 13 12" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...svgProps}>
        <path d="M3.93335 3.5L9.93335 9.5" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" />
        <path d="M3.93335 9.5L9.93335 3.5" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" />
      </svg>
    </div>
  );
};

export default IcCross;
